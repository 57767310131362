import React, { memo, useMemo } from "react";
import { Handle, Position, NodeProps } from "react-flow-renderer";
import { Flex, Input, Text } from "@chakra-ui/react";
import styled from '@emotion/styled'

import withPlayBtn from "@/HOCs/withPlayBtn";
import EnhancedHandle from "@/components/enhancedHandle";
import Themes from "@/themes";

export interface AnnotationNodeData {
  content: string;
  allowInput?: boolean;
  allowOutput?: boolean;
  animated?: boolean;
  onChange: (data: any) => void;
}

const AnimatedText = styled(Text)<{ animated?: boolean }>`
    ${Themes.animations.shake};
    animation: ${({ animated }) => `${animated ? "shake 2s infinite" : "none"}`};
    -webkit-text-fill-color: #f8f8f8;
    text-align: center;
` 

const AnnotationNode = memo(
  ({ data, isConnectable, id }: NodeProps<AnnotationNodeData>) => {
    const { allowInput = false, allowOutput = false, animated = false, content } = data;

    return (
      <>
        {allowInput && (
          <EnhancedHandle
            type="target"
            position={Position.Left}
            isConnectable={isConnectable}
            id="annotation-input"
          />
        )}
        <Flex
          h="100%"
          justify={"center"}
          align="center"
          direction={"column"}
          p={3}
        >
          <AnimatedText mb={1} animated={animated}>
            { content }
          </AnimatedText>
        </Flex>
        {allowOutput && <EnhancedHandle
          annotation="OUT"
          type="source"
          position={Position.Right}
          isConnectable={isConnectable}
          id={`annotation-output`}
        />}
      </>
    );
  }
);

// export default withPlayBtn(AnnotationNode);
export default AnnotationNode;
