import React, { useState, useRef, useEffect, useCallback } from "react";
import ReactFlow, {
  isEdge,
  removeElements,
  addEdge,
  Controls,
  FlowElement,
  OnLoadParams,
  ReactFlowProps,
  Background,
  BackgroundVariant,
  isNode,
  ReactFlowProvider,
  Node,
  OnConnectStartParams,
  HandleType,
} from "react-flow-renderer";

// import { Twilio } from "twilio";
// import 'firebaseui/dist/firebaseui.css';

import QRCode from 'qrcode.react';

import styled from "@emotion/styled";
import {
  Flex,
  HStack,
  Icon,
  Select,
  Button,
  IconButton,
  useMediaQuery,
} from "@chakra-ui/react";
import { Howler } from "howler";
import {
  FaStop,
  FaPlay,
  FaCodepen,
  FaSave,
  FaTrash,
  FaEraser,
  FaCopy,
  FaShareSquare,
} from "react-icons/fa";
import { v4 as uuid4 } from "uuid";
import {
  message,
  notification,
  Spin,
  Tooltip,
  Space,
  Button as AntButton,
} from "antd";
import { User } from "firebase/auth";
import LogRocket from "logrocket";
import posthog from "posthog-js";
import { stringify } from "flatted";

import { SmartEdge, SmartEdgeProvider } from "@/components/smartEdge";
import AudioNode from "@/components/audioNode";
import LoopNode from "@/components/loopNode";
import RandomNode from "@/components/randomNode";
import SwitchNode from "@/components/switchNode";
import IntervalNode from "@/components/intervalNode";
import DrumNode from "@/components/drumNode";
import PlayNode from "@/components/playNode";
import AnnotationNode from "@/components/annotationNode";
import TimeNode from "@/components/timeNode";
import Dock from "@/components/dock";
import PhaseControl from "@/components/phaseControl";
import { elements2Tree, FlowTree, isCyclic } from "@/lib/tree";
import { FlowTreeEvents, NodeTypes } from "@/lib/types";
import {
  handleElementsChange,
  processFlowTree,
  reidentifyElements,
  validateCopiedElements,
  copyToClipboard,
} from "@/utils";
import FlowEventTarget from "@/utils/flowEvents";
import useStore from "@/store/useStore";
import withAuth from "@/HOCs/withAuth";
import {
  getFlows,
  createFlow,
  updateFlow,
  removeFlow,
  getTargetFlow,
} from "@/services/operations";
import questionBtn from "@/assets/question.svg";
import { useMemo } from "react";
import playBtnSvg from "@/assets/play.svg";
import { none } from "rambda";
import {
  getLocalFlow,
  updateLocalFlow,
  getLocalFlowName,
} from "./utils/localFlow";

const connectionLineStyle = { strokeWidth: 2 };

const snapGrid: [number, number] = [20, 20];

const nodeTypes = {
audioNode: AudioNode, 
loopNode: LoopNode, 
randomNode: RandomNode, 
switchNode: SwitchNode, 
intervalNode: IntervalNode, 
drumNode: DrumNode, 
timeNode: TimeNode, 
playNode: PlayNode, 
annotationNode: AnnotationNode,
};

const Container = styled.div`
width: 100vw; 
height: 100vh; 
display: flex; 
flex-direction: column; 
font-weight: 800; 
font-size: 1.3em; 

.modal {
z-index: 10 !important; 
box-shadow: 0 0 0 9999px rgba(0,0,0,0.6); 
position: relative; 
}

.mobile-play-btn {
cursor: pointer; 
}

.reactflow-wrapper {
width: 100%; 
flex: 1; 
}

.react-flow__node {
  min-width: 200px; 
  max-width: 240px; 
  min-height: 120px;
  width: fit-content; 
  height: fit-content; 
  display: flex; 
  flex-direction: column; 
  justify-content: center; 

  border-radius: 5px; 
  border: 2px solid var(--chakra-colors-blackAlpha-600) !important;  
  background-color: #9CA0D1;  
  color: #fff;  
  
  .node-button {
    visibility: hidden; 
    opacity: 0;  
    transition: opacity 0.3s, visibility 0.3s; 
  }
  
  &:hover {
    .node-button {
      visibility: visible;  
      opacity: 1;  
      transition: opacity 0.3s; 
    }
  }
  }

  .react-flow__node-active {
    background-color: var(--chakra-colors-yellow-500) !important; 
    }
    
    .react-flow__node.selected {
    border: 2px solid var(--chakra-colors-blackAlpha-600) ; 
    background-color: rgba(95,97,137,0.7); 
    }
    
    // .react-flow__node-playNode {
    // max-width: 300px;
    // }
    
    .react-flow__edges {
    z-index: 3; 
    }
    
    .react-flow__edges-temp {
    z-index: 2; 
    }
    
    .react-flow__edge {
    cursor: pointer; 
    }

    .react-flow__edge-path-selector:hover { cursor: pointer; } 
    .react-flow__edge-path-selector {
    fill: none; 
    stroke: transparent; 
    stroke-width: 28; 
    }

    .react-flow__edge-path-selector:hover + .react-flow__edge-path, .react-flow__edge-path:hover {
    stroke-width: 6 !important; 
    }
    
    .react-flow__edge-path-selector:hover ~ .edgebutton-foreignobject div {
    visibility: visible; 
    }
    .react-flow__edge-path:hover ~ .edgebutton-foreignobject div {
    visibility: visible;
    }
    
    .react-flow__edge > .react-flow__edge-path {
    stroke: #96B38A; 
    stroke-width: 4; 
    }
    
    .react-flow__edge.selected > .react-flow__edge-path {
    stroke: var(--chakra-colors-blue-500);
    }

 .react-flow__edge-active .react-flow__edge-path {
stroke: var(--chakra-colors-yellow-500) !important; 
}

*:active .react-flow__edge > .react-flow__edge-path,
*:active .react-flow__edge > .react-flow__edge-path-selector { cursor: default; } 

.react-flow__handle {
background-color: #96B38A; 
height: 14px; 
width: 14px; 
transition: background-color 0.3s; 
&:hover {
height: 28px; 
width: 28px; 
background-color: #53644D; 
}
}
.react-flow__handle-left {
left: -8px; 
&:hover {
left: -16px; 
}
}
.react-flow__handle-right {
right: -8px; 
&:hover {
right: -16px; 
}
}

.edgebutton {
width: 50px; 
height: 50px; 
cursor: pointer; 
border-radius: 50%; 
line-height: 1; 
}

.edgebutton-foreignobject:hover div {
visibility: visible; 
}

.edgebutton-foreignobject div {
background: transparent; 
width: 40px; 
height: 40px; 
justify-content: center; 
align-items: center; 
min-height: 40px; 
display: flex; 
visibility: hidden; 
transition-property: visibility; 
transition-duration: 2s; 
}
}

.react-flow__edge-path-selector:hover + .cursorToolTip {
z-index: 1000; 
display: block ; 
}


.react-flow__edge-path-selector .cursorToolTip {
  display: block;
  background: #C8C8C8;
  margin-left: 28px;
  padding: 10px;
  position: absolute;
  z-index: 1000 ;
  width:25px;
  height:25px;
}


// Styles For Sharing Board by LZF
.popup {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000; 
}

.popup-content {
  background-color: white;
  border-radius: 8px;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
  padding: 20px;
  text-align: center;
}

.popup-title {
  font-size: 24px;
  margin-top: 0;
}

.buttons-container {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}


.close-button {
  margin-top: 20px;
  background-color: #ccc;
  border: none;
  padding: 10px 20px;
  cursor: pointer;
  border-radius: 4px;
}

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.6); 
  display: flex;
  justify-content: center; 
  align-items: center; 
  z-index: 1000; 
}

.mymodal {
  background: white;
  padding: 20px;
  border-radius: 5px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
  text-align: center;
  position: relative; 
  max-width: 100%;
}

.modal-title {
  font-size: 24px;
}


.close-button {
  position: absolute;
  top: 10px;
  right: 10px;
  background-color: #ccc;
  border: none;
  padding: 5px 10px;
  border-radius: 5px;
  cursor: pointer;
}

.modal-buttons {
  display: flex;
  justify-content: center; 
  margin-top: 20px; 
}


.modal-button {
  margin: 0 30px;
  display: inline-block;
  padding: 10px 20px;
  background-color: #007bff;
  color: #fff;
  text-align: center;
  border: none;
  cursor: pointer;
  border-radius: 25px; 
  font-size: 16px;
  transition: background-color 0.3s ease;

  &:hover {
    background-color: #0056b3;
  }

}



.phoneNumberInput {
  border: 1px solid black;
  padding: 8px;
  width: 250px; 
}

.sendSMSButton {
  border: 1px solid black;
  padding: 8px 16px; 
  background-color: transparent; 
  color: black; 
  cursor: pointer; 
}

.phoneNumberInput::placeholder {
  font-size: 14px;
}

`;

// Styles for the ActionContainer component
const ActionContainer = styled(HStack)`
  position: absolute;
  bottom: 12px;
  right: 10px;
  z-index: 999;
  flex-wrap: wrap;
  justify-content: flex-end;
  
  @media screen and (max-width: 768px) {
    justify-content: flex-start;
    padding: 8px
  }
`;


const EXAMPLE_FLOW_ID = "-NLcGZH_yZOKTqx3K15w"; 
const EXAMPLE_USER_ID = "uHqp50tGEKZXhC8qpIsV5EMm8dr1";
const SHARE_USER_ID = "shared_uid"; 
const EXAMPLE_ANNOTATION_ID = "example_annotationNode"; 

let copiedURL = "";

let EXAMPLE_TIMER; 

const Home: React.FC<{
  user?: User; 
  setRequiredAuth: (required: boolean) => void; 
  afterAuthCallback: React.MutableRefObject<Function>; 
  signOut: () => void;
}> = ({ user, setRequiredAuth, afterAuthCallback, signOut }) => {
  const reactFlowWrapper = useRef<HTMLDivElement>(null); 
  const counterRef = useRef<number>(0);
  const elementsRef = useRef<FlowElement[]>([]); 
  const selectedElementsRef = useRef<FlowElement[]>([]); 
  const copiedElementsRef = useRef<FlowElement[]>([]); 
  const ctrldownRef = useRef<boolean>(false);
  const mousePosRef = useRef<{ x: number; y: number }>(); 
  const urlParamsRef = useRef<{
    userID?: string;
    flowID?: string;
    [key: string]: any;
  }>({}); 

  const [reactflowInstance, setReactflowInstance] = useState<OnLoadParams>();
  const [elements, setElements] = useState<FlowElement[]>([]);
  const [eventTarget, setEventTarget] = useState<FlowEventTarget>();
  const [processRunning, setProcessRunning] = useState<boolean>(false);
  const [storedFlows, setStoredFlows] = useState<{ [key: string]: any }>({});
  const [selectedFlow, setSelectedFlow] = useState<string>("draft");
  const [loading, setLoading] = useState(false);
  const [hasPlayedExample, setHasPlayedExample] = useState(
    !!localStorage.getItem("hasPlayedExample")
  );
  const [hasFinishedExample, setHasFinishedExample] = useState(false);

  const isExampleRef = useRef<boolean>(false);
  const hasPlayedExampleRef = useRef<boolean>(false);

  const storedFlowsRef = useRef<{ [key: string]: any }>({});
  const processRunningRef = useRef<boolean>(processRunning);
  const startHandleRef = useRef<OnConnectStartParams>();

  const addActiveNode = useCallback(
    useStore((state) => state.addActiveNode),
    []
  );
  const removeActiveNode = useCallback(
    useStore((state) => state.removeActiveNode),
    []
  );

  const addActiveEdges = useCallback(
    useStore((state) => state.addActiveEdges),
    []
  );
  const removeActiveEdges = useCallback(
    useStore((state) => state.removeActiveEdges),
    []
  );

  const setAudioNodeProgress = useCallback(
    useStore((state) => state.setAudioNodeProgress),
    []
  );

  const setLoopCount = useCallback(
    useStore((state) => state.setLoopCount),
    []
  );

  const setProcessID = useCallback(
    useStore((state) => state.setProcessID),
    []
  );

  const onStopFlowProcess = (hardStop: boolean) => {
    console.log("Process Stopped", hardStop);
    setProcessRunning(false);
    if (hardStop) {
      setProcessID(uuid4());
      Howler.stop();
    }
    posthog.capture("onStopWholeProcess");

    if (isExampleRef.current && !hasPlayedExample) {
      setHasFinishedExample(true);
      // fake a click on the draft template
      onSelectTemplate({ target: { value: "draft" } } as any);
      message.info("Now you can play with the empty flow.");
    }
  };

  const onStartFlowProcess = ({
    tree,
    startNewProcess,
    hardStop,
  }: {
    tree: FlowTree;
    hardStop?: boolean;
    startNewProcess?: boolean;
  }) => {
    const cycled = isCyclic(tree.getTreeLikeData());

    setProcessRunning(true);
    // Howler.stop();

    let curProcessId;

    if (startNewProcess) {
      curProcessId = uuid4();
    } else {
      curProcessId = useStore.getState().processID ?? uuid4();
    }

    setProcessID(curProcessId);
    processFlowTree({
      tree,
      processID: curProcessId,
      cycled,
      beforeProcessCallback: (eventTarget, processID) => {
        // TODO: memory leak?
        eventTarget.addEventListener(FlowTreeEvents.AddActiveNode, (event) => {
          addActiveNode((event as CustomEvent).detail);
          
          console.log("AddActiveNode");
        });

        eventTarget.addEventListener(
          FlowTreeEvents.RemoveActiveNode,
          (event) => {

          console.log("RemoveActiveNode");
            removeActiveNode((event as CustomEvent).detail);
          }
        );

        eventTarget.addEventListener(FlowTreeEvents.AddActiveEdge, (event) => {
          
          console.log("AddActiveEdge");
          addActiveEdges((event as CustomEvent).detail);
        });

        eventTarget.addEventListener(
          FlowTreeEvents.RemoveActiveEdge,
          (event) => {

            console.log("RemoveActiveEdge");
            removeActiveEdges((event as CustomEvent).detail);
          }
        );

        eventTarget.addEventListener(
          FlowTreeEvents.UpdateAudioNodeProgress,
          (event) => {
            console.log("UpdateAudioNodeProgress");
            setAudioNodeProgress((event as CustomEvent).detail);
          }
        );

        eventTarget.addEventListener(FlowTreeEvents.ProcessEnd, (event) => {
          console.log("Process End", event);
          onStopFlowProcess(hardStop);
        });
        
        eventTarget.addEventListener(
          FlowTreeEvents.UpdateLoopCount,
          (event) => {
            console.log("UpdateLoopCount");
            setLoopCount((event as CustomEvent).detail);
          }
        );

        setEventTarget(eventTarget);
      },
      shouldUpdateActiveNode: !processRunningRef.current,
    });
  };

  // all the flows
  const flowOptions = useMemo(() => {
    storedFlowsRef.current = storedFlows;
    console.log("flows changed", storedFlowsRef.current, storedFlows);

    return [
      ...Object.entries(storedFlows || {})
        .filter(([key, val]) => !!val)
        .map(([key, val]) => {
          return {
            text:
              val.userID && val.userID !== user?.uid
                ? `Shared: ${val.name}`
                : val.name,
            value: key,
          };
        })
        .sort((a, b) => {
          const nameA = a?.text?.toUpperCase?.(); // ignore upper and lowercase
          const nameB = b?.text?.toUpperCase?.(); // ignore upper and lowercase
          if (nameA == "NEW PROJECT") {
            return 1;
          }
          if (nameB == "NEW PROJECT") {
            return -1;
          }
          if (nameA < nameB) {
            return -1;
          }
          if (nameA > nameB) {
            return 1;
          }
          // names must be equal
          return 0;
        }),
        // TODO
      // { text: "New Project", value: "draft" },
    ];
  }, [storedFlows]);

  const activeEdge = useStore((state) => state.activeEdge);
  const activeNode = useStore((state) => state.activeNode);

  const onNodeChange = (event: any) => {
    setElements((els) => handleElementsChange(event, els));
  };

  const onPlayNode = (node: Partial<Node>) => {
    const tree = elements2Tree([...elementsRef.current]);
    const subTree = tree.findNode(node.id);

    posthog.capture("onStartElement", { element: node });
    if (subTree) {
      try {
        onStartFlowProcess({
          tree: subTree,
          hardStop: false,
        });

        // set flag of hasPlayedExample
        if (!hasPlayedExampleRef.current && isExampleRef.current) {
          setHasPlayedExample(true);
          localStorage.setItem("hasPlayedExample", "true");
        }
      } catch (err) {
        console.log(err);
        onStopFlowProcess(true);
      }
    }
  };

  useEffect(() => {
    LogRocket.identify(user?.uid, {
      name: user?.displayName,
      email: user?.email,
    });
    posthog.init("phc_31XQu91cgkYfdlMcjnJQh94Gd5NG38Pjs1PxBQeeVQe", {
      api_host: "https://app.posthog.com",
    });

    const searchParams = new URLSearchParams(location.search);
    const flowID = searchParams.get("flowID");
    const urlUserID = searchParams.get("userID");

    if (flowID && urlUserID) history.pushState(null, "", "/");
    if (flowID && urlUserID) {
      console.log("flowID && urlUserID");
      onLoadFlows(urlUserID, flowID);
    } else {
      console.log("else");
      onLoadFlows(
        undefined,
        hasPlayedExample ? localStorage.getItem("latestFlow") : EXAMPLE_FLOW_ID,
        true
      );
    }
  }, []);

  useEffect(() => {
    LogRocket.identify(user?.uid, {
      name: user?.displayName,
      email: user?.email,
    });

    posthog.identify(user?.uid, {
      name: user?.displayName,
      email: user?.email,
    });

    if (user) {
      setTimeout(() => {
        afterAuthCallback.current?.(user.uid);
      }, 1000);
    }
  }, [user]);

  useEffect(() => {
    isExampleRef.current = selectedFlow === EXAMPLE_FLOW_ID;
  }, [selectedFlow, loading]);

  useEffect(() => {
    hasPlayedExampleRef.current = hasPlayedExample;
  }, [hasPlayedExample]);

  useEffect(() => {
    document.body.addEventListener("keydown", onKeyDown);
    document.body.addEventListener("keyup", onKeyUp);
    document.body.addEventListener("mousemove", onMouseMove);
    document
      .querySelector(".reactflow-wrapper")
      ?.addEventListener("click", onClickScreen);

    return () => {
      document.body.removeEventListener("keydown", onKeyDown);
      document.body.removeEventListener("keyup", onKeyUp);
      document.body.removeEventListener("mousemove", onMouseMove);
      document
        .querySelector(".reactflow-wrapper")
        ?.removeEventListener("click", onClickScreen);
    };
  }, [reactflowInstance]);

  useEffect(() => {
    // autosave
    const currentFlowID = localStorage.getItem("latestFlow");
    if (currentFlowID !== "draft") {
      const flowData = storedFlows[currentFlowID];
      const urlUserID = urlParamsRef.current.userID;

      if (!user && urlUserID === SHARE_USER_ID) {
        // everyone can change the shared flow without login status
        updateFlow(urlUserID, currentFlowID, elements, flowData.name);
      } else if (
        urlUserID &&
        user?.uid === urlUserID &&
        currentFlowID !== EXAMPLE_FLOW_ID
      ) {
        // user's own flows
        // we don't allow updates on others' flows
        updateFlow(urlUserID, currentFlowID, elements, flowData.name);
      }
      // update the elements in a target flow without re-rendering
      setStoredFlows((stored) => {
        if (stored[currentFlowID] && currentFlowID !== EXAMPLE_FLOW_ID) {
          stored[currentFlowID].elements = elements;
        }
        return stored;
      });
    } else {
      // draft (New Project)
      // store to localStorage
      console.log("update localFlow");
      const removeToEmpty = window.sessionStorage.getItem("removeToEmpty");
      console.log("removeToEmpty: ", removeToEmpty);
      if (elements.length == 0 && removeToEmpty !== "true") {
        // not onElementsRemove made elements empty
        // ignore this case
        return;
      }
      updateLocalFlow(elements);

      setStoredFlows((stored) => {
        if (stored[currentFlowID]) {
          stored[currentFlowID].elements = elements;
        }
        return stored;
      });
    }

    elementsRef.current = elements;

    copiedElementsRef.current = copiedElementsRef.current?.map((elm) => {
      const node = elements.find((el) => el.id === elm.id);
      return node ?? elm;
    });
  }, [elements]);

  useEffect(() => {
    if (reactflowInstance) {
      reactflowInstance.fitView({ padding: 1000 });
      reactflowInstance.zoomTo(0);
    }
  }, [selectedFlow]);

  useEffect(() => {
    if (reactflowInstance && !loading) {
      setTimeout(() => reactflowInstance.fitView({ padding: 0.5 }), 0);
    }
  }, [loading]);

  useEffect(() => {
    if (!activeEdge) return;
    setElements((els) =>
      els.map((elm) => {
        //If node active, flash the node, if Edge's target active, flash the edge.
        if (isEdge(elm)) {
          return {
            //problem: useeffect trigger for every node, wipe out class for other nodes .
            ...elm,
            className: !!activeEdge.get(elm.target)
              ? "react-flow__edge-active"
              : "",
          };
        } else {
          // if (elm.type == 'loopNode') {
          //   return {
          //     ...elm,
          //     className: !!activeNode.get(elm.id)
          //       ? "react-flow__node"
          //       : "",
          //   };
          // }
          return {
            ...elm,
            className: !!activeNode.get(elm.id)
              ? "react-flow__node-active"
              : "",
          };
        }
      })
    );
  }, [activeEdge, activeNode]);

  useEffect(() => {
    processRunningRef.current = processRunning;
  }, [processRunning]);

  // Remove the example annotation highlight
  useEffect(() => {
    if (hasPlayedExample) {
      for (let edge of Array.from(
        document.querySelectorAll(".react-flow__edges")
      )) {
        edge?.classList?.remove("react-flow__edges-temp");
      }
      document
        .querySelector(`[data-id=${EXAMPLE_ANNOTATION_ID}]`)
        ?.classList?.remove("modal");
    }
  }, [hasPlayedExample]);

  const onKeyUp = (e: KeyboardEvent) => {
    if (e.keyCode == 17 || e.keyCode == 91) {
      ctrldownRef.current = false;
    }
  };

  const onKeyDown = (e: KeyboardEvent) => {
    if (e.keyCode == 17 || e.keyCode == 91) {
      ctrldownRef.current = true;
    }

    // copy
    if (ctrldownRef.current && e.keyCode == 67) {
      copiedElementsRef.current = selectedElementsRef.current;
      posthog.capture("copyElements", {
        payload: stringify(copiedElementsRef.current),
      });
    }

    // paste
    if (ctrldownRef.current && e.keyCode == 86) {
      if (
        !copiedElementsRef.current ||
        copiedElementsRef.current.length === 0
      ) {
        return;
      }

      if (!validateCopiedElements(copiedElementsRef.current)) {
        message.error(
          "When you copy and paste edges, please make sure you copy both ends of the edge"
        );
        return;
      }

      const reactFlowBounds = reactFlowWrapper.current.getBoundingClientRect();
      const position = reactflowInstance.project({
        x: mousePosRef.current?.x - reactFlowBounds.left,
        y: mousePosRef.current?.y - reactFlowBounds.top,
      });

      posthog.capture("pastedElements", {
        payload: stringify(copiedElementsRef.current),
      });

      setElements((es) => {
        const newElements = reidentifyElements({
          elms: copiedElementsRef.current,
          counterRef,
          reactflowInstance,
          reactFlowBounds,
          basePositionX: position.x,
          basePositionY: position.y,
          positionX: position.x,
          positionY: position.y,
          commonData: {
            ...commonElementData,
          },
        });
        return es.concat(newElements);
      });
    }
  };

  const onMouseMove = (e: MouseEvent) => {
    mousePosRef.current = {
      x: e.clientX,
      y: e.clientY,
    };
  };

  const onClickScreen = (e: MouseEvent) => {
    clearTimeout(EXAMPLE_TIMER);
    setSelectedFlow((flow) => {
      EXAMPLE_TIMER = setTimeout(() => {
        if (isExampleRef.current && !hasPlayedExampleRef.current) {
          const firstPlayNode = elementsRef.current.find(
            (elem) => elem.type === "playNode"
          );
          onPlayNode({ id: firstPlayNode.id });
        }
      }, 10);

      return flow;
    });
  };

  const onPlayEntireProcess = () => {
    console.log("this is the Onplay, with value of processRunning: ", processRunning);
    const tree = elements2Tree([...elementsRef.current]);
    const roots = tree.getRoots();

    console.log("the first root node is  ",tree);
    console.log("the first root node is  ",roots);
    if (roots?.length > 0) {
      // find the first root node to start playing the flow
      // console.log("the first root node is found ");
      const firstNodeToPlay = roots[0];
      onPlayNode({ id: firstNodeToPlay.id });
    }
  };

  const onElementsRemove = useCallback((elementsToRemove) => {
    posthog.capture("onRemoveElement", {
      payload: stringify(elementsToRemove),
    });
    console.log("onElementsRemove");
    onStopFlowProcess(true);
    // indicate that remove action makes elements empty
    window.sessionStorage.setItem("removeToEmpty", "true");
    return setElements((els) => removeElements(elementsToRemove, els));
  }, []);

  const onConnect = useCallback((params) => {
    posthog.capture("onConnect", { payload: stringify(params) });
    console.log("onConnect", params);
    setElements((els) =>
      addEdge(
        {
          ...params,
          //style: { strokeWidth: 4 },
          type: "smart",
          arrowHeadType: "arrow",
        },
        els
      )
    );
  }, []);

  // triggered once when page loaded.
  const onLoad = useCallback(
    (rfi: OnLoadParams) => {
      if (!reactflowInstance) {
        setReactflowInstance(rfi);
        console.log("flow loaded:", rfi);
        posthog.capture("onLoad");
      }
    },
    [reactflowInstance]
  );

  const getFlowFromQuery = async (
    userID: string,
    flowID: string,
    recordParams: boolean = true
  ) => {
    if (recordParams) {
      urlParamsRef.current.flowID = flowID;
      urlParamsRef.current.userID = userID;
    }
    if (flowID && userID) {
      const flow = await getTargetFlow(userID, flowID);
      if (flow) {
        flow.userID = userID;
      }
      return { flow, flowID };
    }
  };

  const onLoadFlows = async (
    uid?: string,
    defaultFlow?: string,
    updateLoading?: boolean
  ) => {
    // avoid triggering updateLocalFlow when passing empty elements
    window.sessionStorage.removeItem("removeToEmpty");
    if (updateLoading) setLoading(true);
    // get saved flows from firebase
    let flows = await getFlows(user?.uid ?? 'Empty'); //SHARE_USER_ID if set as shared_uid people see projects under shared uid which not created by them // by zf
    let currentFlow = { flow: flows?.[defaultFlow], flowID: defaultFlow };
    if (currentFlow.flow === undefined)
      currentFlow = await getFlowFromQuery(uid, defaultFlow);
    console.log("currentFlow: ", currentFlow);
    function annotateExample(exampleFlow) {
      // 播放example的动画 在哪执行完的
      // We want to add a note to ask users to play the block
      const firstPlayNode = exampleFlow.flow.elements.find(
        (elem) => elem.type === "playNode"
      );

      exampleFlow.flow.elements = [
        ...exampleFlow.flow.elements,
        {
          data: {
            content: "Click the screen to hear the flow",
            animated: true,
          },
          id: EXAMPLE_ANNOTATION_ID,
          position: {
            x: firstPlayNode.position.x - 300,
            y: firstPlayNode.position.y,
          },
          type: "annotationNode",
        },
      ];

      // // After adding the annotation, you can add an event listener
      // // to automatically redirect when the annotation node is clicked
      // annotationNode.data.onClick = () => {
      // // Replace 'new-project-url' with the actual URL of your new project
      //   window.location.href = 'new-project-url';
      // }; // lzf

      // this highlight the annotation node
      // meanwhile, we need to change the z-index of edges temporarily so that they are at the back
      // of the backdrop
      setTimeout(() => {
        const annotationNode = document.querySelector(
          `[data-id=${EXAMPLE_ANNOTATION_ID}]`
        );

        if (!hasPlayedExampleRef.current && annotationNode) {
          for (let edge of Array.from(
            document.querySelectorAll(".react-flow__edges")
          )) {
            edge.classList.add("react-flow__edges-temp");
          }
          annotationNode?.classList?.add("modal");
        }
      }, 200);

      return exampleFlow;
    }

    if (currentFlow) {
      // If the currentFlow is available
      // Check if the URL points to the example flow
      if (currentFlow.flowID === EXAMPLE_FLOW_ID) {
        // Annotate the example flow to guide users
        currentFlow = annotateExample(currentFlow);
      }

      // Update the flows object with the currentFlow
      flows = {
        ...flows,
        [currentFlow.flowID]: currentFlow.flow,
      };
    }

    // Show the example flow if
    // 1. The URL does not contain a flow ID
    // 2. It's the first time the user visits the app
    if (!currentFlow && !hasPlayedExample) {
      // Retrieve the example flow using predefined IDs
      let exampleFlow = await getFlowFromQuery(
        EXAMPLE_USER_ID,
        EXAMPLE_FLOW_ID
      );

      if (exampleFlow) {
        // Annotate the example flow to guide users
        exampleFlow = annotateExample(exampleFlow);
        // Update the flows object with the annotated exampleFlow
        flows = {
          ...flows,
          [exampleFlow.flowID]: exampleFlow.flow,
        };
      }
    }

    // add draft (local flow) to flows
    flows = {
      ["draft"]: getLocalFlow(),
      ...flows,
    };
    console.log("flows: ", flows);
    // TODO:
    // somehow this does not trigger re-render when users just logged in
    // e.g., unauthenticated -> play with draft -> click save -> authenticated
    setStoredFlows(flows ?? {});
    storedFlowsRef.current = flows;
    // select the default flow if available
    if (currentFlow) {
      // settimeout to make sure that storedFlows has been updated
      setTimeout(() => {
        // call onSelectTemplate to load the exact flow
        onSelectTemplate({ target: { value: currentFlow.flowID } } as any);
      }, 50);
    } else {
      setTimeout(() => {
        let flowID: string;
        if (flowOptions.length) {
          flowID = flowOptions[0].value;
        }
        let flowsKeys = Object.keys(flows); // TODO

        if (flowsKeys.length > 0) {
          // [flowsKeys.length - 1] points to the exampleFlow
          flowID = flowsKeys[flowsKeys.length - 1];
        }
        onSelectTemplate({ target: { value: flowID } } as any);
      }, 50);
    }
    // somehow the TODO above will throw error
    // we need another way to trigger onSelectTemplate
    // when user
    // if (flows.length == 1 && flows[0].)
    if (updateLoading) setLoading(false);
  };
  // const [playNodeCount, setPlayNodeCount] = useState(0);

  const onDragOver = (event) => {
    event.preventDefault();
    event.dataTransfer.dropEffect = "move";
  };
  const onDrop = (event) => {
    event.preventDefault();

    const reactFlowBounds = reactFlowWrapper.current.getBoundingClientRect();
    const type = event.dataTransfer.getData("application/reactflow");
    const storedFlowID = event.dataTransfer.getData("application/id");
    const position = reactflowInstance.project({
      x: event.clientX - reactFlowBounds.left,
      y: event.clientY - reactFlowBounds.top,
    });

    // do not proceed if it is not a known node
    if (!type) {
      return;
    }

    if (type === NodeTypes.moduleNode && storedFlowID) {
      const stored = storedFlows[storedFlowID];
      if (stored) {
        console.log("stored", stored);
        posthog.capture("onCreateSubroutine", {
          payload: stringify(stored.elements),
        });
        setElements((es) =>
          es.concat(
            reidentifyElements({
              elms: stored.elements,
              counterRef,
              reactflowInstance,
              reactFlowBounds,
              basePositionX: position.x,
              basePositionY: position.y,
              // positionX: position.x,
              // positionY: position.y,
              commonData: {
                ...commonElementData,
              },
            })
          )
        );
      }
      return;
    }

    setElements((prevElements) => {
      console.log("prevElements: ", prevElements);
      // 如果是playNode类型，则更新所有现有playNode的order并添加新playNode
    if (type === 'playNode') {
      // const playNodes = prevElements.filter(el => el.type === 'playNode');
      // const nonPlayNodes = prevElements.filter(el => el.type !== 'playNode');
      
      // // 更新现有playNodes的order
      // const updatedPlayNodes = playNodes.map((node, index) => ({
      //   ...node,
      //   data: {
      //     ...node.data,
      //     order: index, // 重新计算order
      //   },
      // }));
      // // 创建新的playNode，其order为现有playNodes数量加1
      // const newPlayNode = {
      //   id: `${counterRef.current}_${uuid4()}_${type}`,
      //   type,
      //   position,
      //   data: {
      //     label: `${type} node (Order: ${playNodes.length})`,
      //     ...commonElementData,
      //     order: playNodes.length, // 设置新playNode的order值
      //   },
      // };

      // console.log('A new playNode added! With number:', playNodes.length + 1);
      // posthog.capture("onCreateElement", { payload: stringify(newPlayNode) });
      // counterRef.current += 1;

      // // 返回更新后的数组，包括更新的playNodes和新的playNode
      // return [...nonPlayNodes, ...updatedPlayNodes, newPlayNode];
      const playNodes = prevElements.filter(el => el.type === 'playNode');
      const nonPlayNodes = prevElements.filter(el => el.type !== 'playNode');
      
      // 计算最大的order值
      const maxOrder = playNodes.reduce((max, node) => (node.data.order > max ? node.data.order : max), -1);
      
      // 创建新的playNode，其order为最大order值加1
      const newPlayNode = {
        id: `${counterRef.current}_${uuid4()}_${type}`,
        type,
        position,
        data: {
          label: `${type} node (Order: ${maxOrder + 1})`,
          ...commonElementData,
          order: maxOrder + 1, // 设置新playNode的order值
        },
      };
      
      console.log('A new playNode added! With number:', maxOrder + 1);
      posthog.capture("onCreateElement", { payload: stringify(newPlayNode) });
      counterRef.current += 1;
      
      // 返回更新后的数组，包括更新的playNodes和新的playNode
      return [...nonPlayNodes, ...playNodes, newPlayNode];

    } else {
      // 对于非playNode类型的节点，直接添加到elements数组
      const newNode = {
        id: `${counterRef.current}_${uuid4()}_${type}`,
        type,
        position, 
        data: {
          label: `${type} node`,
          ...commonElementData,
        },
      };

      posthog.capture("onCreateElement", { payload: stringify(newNode) });
      counterRef.current += 1;

      // 返回更新后的elements数组，包括新的非playNode节点
      return [...prevElements, newNode];
    }
  });

  };
  const handleProcessBtn = (e) => {
    console.log("elements2Tree", elements2Tree(elements));

    if (!processRunning) {
      posthog.capture("onStartWholeProcess");
    }

    if (processRunning) {
      onStopFlowProcess(true);
    } else {
      onStartFlowProcess({
        tree: elements2Tree(elements),
        startNewProcess: true,
        hardStop: true,
      });
    }
  };

  const onSelectTemplate: React.ChangeEventHandler<HTMLSelectElement> = (e) => {
    posthog.capture("onChangeProject", { payload: e.target.value });
    localStorage.setItem("latestFlow", e.target.value);
    switch (e.target.value) {
      case "draft":
        // load localFlow
        if (!storedFlowsRef.current[e.target.value]) return;
        const localFlow = getLocalFlow();
        const localElements = localFlow.elements;
        localElements.map((element) => {
          if (isNode(element)) {
            element.data = {
              ...(element.data ?? {}),
              ...commonElementData,
            }
            return element;
          }
          return element;
         })
        setElements(localElements);
        console.log("onSelectTemplate load localFlow:", localFlow);
        urlParamsRef.current.flowID = undefined;
        break;
      default:
        const stored = storedFlowsRef.current[e.target.value];
        if (!stored) return;
        stored.elements = (stored.elements ?? []).map((elem) => {
          if (isNode(elem)) {
            // add functions to nodes
            elem.data = {
              ...(elem.data ?? {}),
              ...commonElementData,
            };
            return elem;
          }
          return elem;
        });
        setElements(stored.elements);
        urlParamsRef.current.flowID = e.target.value;
        urlParamsRef.current.userID = stored.userID ?? user?.uid;
        break;
    }
    setSelectedFlow(e.target.value);
  };

  const onSaveFlow = async (uid: string, after_login: boolean) => {
    let name = "shareExample";
    if(user || after_login)
    {
      name = prompt("What is the name of your project?");
    
    if (name) {
      console.log('elements before save:', elements);
      const flowID = await createFlow(uid, name, elements);
      await onLoadFlows(uid, flowID, false); //by zf
      console.log("flowID", flowID);
      posthog.capture("onSaveProject", {
        payload: stringify({ uid, name, elements }),
      });
      return flowID;
    } else {
      alert("Project name is empty. Saving Cancelled.");
    }
  }else{
      const flowID = await createFlow(uid, name, elements); // not login users should not be asked for project name
      await onLoadFlows(uid, flowID, false);
      console.log("flowID", flowID);
      posthog.capture("onSaveProject", {
        payload: stringify({ uid, name, elements }),
      });
      return flowID;
  }
  };

  const onRelayout = async () => {
    // not used for now
  };

  const onNodeDragStop: ReactFlowProps["onNodeDragStop"] = (event, node) => {
      const updatedElements = elements.map((elm) => {
        if (elm.id === node.id) {
          return node;
        }
        return elm;
      });

      setElements(updatedElements);
      // updateFlow(
      //   user?.uid,
      //   selectedFlow,
      //   updatedElements
      // );

    posthog.capture("onMoveElement", {
      payload: stringify({ element: node, event }),
    });
  };

  const onElementClick: ReactFlowProps["onElementClick"] = (event, element) => {
    posthog.capture("onClickElement", {
      payload: stringify({ element, event }),
    });
    console.log("click", element);
  };


  const ShareProject = async (uid, viaID = 0) => {
    //修改此函数以实现弹出框和不同类型的分享选项
    // let copiedURL = "";
  

    if (viaID === 0) {
      // setShowPopup(true);//显示弹窗

    } else if (viaID === 1) {
      // copy link

      copyToClipboard(copiedURL);
      message.info("Link copied to clipboard!");
      setIsModalOpen(false);

    } else if (viaID === 2) {
      // Via Email

      message.info("Email is opened!");

      window.open(
        `mailto:?to=&body=Hi! Checkout my MFlow Project at ${encodeURIComponent(
          copiedURL
        )}!&subject=MFlow Project Sharing`
      );
    } 
    /*
    else if (viaID === 3) { // This feature is cancled
      // Via SMS
      // Download the helper library from https://www.twilio.com/docs/node/install
      // Find your Account SID and Auth Token at twilio.com/console
      // and set the environment variables. See http://twil.io/secure
      const accountSid = import.meta.env.VITE_TWILIO_ACCOUNT_SID;
      const authToken = import.meta.env.VITE_TWILIO_AUTH_TOKEN;
      const twilioPhoneNumber = "+18776242057"; // Twilio 所分配的电话号码 (858) 914-4793

      const flow_url_message =
        "You've received a project sharing link from Mflow(https://mflow.sciencemusic.org/). Click to view: " +
        copiedURL;
      const recipientPhoneNumber = phoneNumber; // 收件人的电话号码

      fetch(
        `https://api.twilio.com/2010-04-01/Accounts/${accountSid}/Messages.json`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
            Authorization: `Basic ${btoa(`${accountSid}:${authToken}`)}`, // 使用 Basic Auth
          },
          body: new URLSearchParams({
            From: twilioPhoneNumber,
            To: recipientPhoneNumber,
            Body: flow_url_message,
          }),
        }
      )
        .then((response) => response.json())
        .then((data) => console.log(data))
        .catch((error) => console.error("Error sending SMS:", error));

      message.info("Shared Project URL was Sent by SMS!");
      setIsContainerVisible(!isContainerVisible);

      setIsModalOpen(false);

    } */
    else if(viaID === 4){
      //显示二维码
      showQRcode(copiedURL);
    }
    
  };

  const onOpenURLEmailNotification = (url: string) => {
    const key = `open${Date.now()}`;
    const btn = (
      <Space>
        <AntButton
          type="primary"
          size="small"
          onClick={() => {
            window.open(
              `mailto:?to=&body=Hi! Checkout my MFlow Project at ${encodeURIComponent(
                url
              )}!&subject=MFlow Project Sharing`
            );
            notification.close(key);
          }}
        >
          Send with Email
        </AntButton>
      </Space>
    );
    notification.open({
      message: "Share with Email?",
      description:
        "Do you want to share this project with your friends via email?",
      btn,
      key,
    });
  };

  const commonElementData = {
    onChange: onNodeChange,
    onPlay: onPlayNode,
    onStop: onStopFlowProcess,
    onRemove: onElementsRemove,
  };

  // enable/disable hidden handles by setting height and width to 100% or 0%.
  const setHiddenHandles = (enable: boolean) => {
    const value = enable ? "100%" : "0%";
    const hiddenHandle = document.querySelectorAll(
      ".hiddenHandle"
    ) as NodeListOf<HTMLElement>;
    hiddenHandle.forEach((el) => {
      //set height and width to 100% to make it visible
      el.style.height = value;
      el.style.width = value;
    });
  };

  const [isMobile] = useMediaQuery("(max-width: 768px)"); // 768px

  function Modal(props) {
    const { isOpen, onClose, children } = props;

    return isOpen ? (
      <div className="mymodal">
        <div className="model-overlay">
          <span className="close-button" onClick={onClose}>
            &times;
          </span>
          {children}
        </div>
      </div>
    ) : null;
  }

  const [isModalOpen, setIsModalOpen] = useState(false);

  const openModal = () => setIsModalOpen(true);
  const closeModal = () => setIsModalOpen(false);

  // const smsInputContainer = document.getElementById("phoneNumberContainer");
  const [isContainerVisible, setIsContainerVisible] = useState(false);

  const toggleContainer = () => {
    setIsContainerVisible(!isContainerVisible);
  };
  // const phoneNumberInputRef = useRef(null); 
  // const phoneNumberInput = document.getElementById('phoneNumberInput');
  // const sendSmsButton = document.getElementById('sendSMSButton');

  // const [phoneNumber, setPhoneNumber] = useState(""); 


  // const handleInputChange = (event) => {
  //   phoneNumberInputRef.current.focus();
  //   setPhoneNumber(event.target.value);
  //   // phoneNumberInputRef.current.focus();
  // };


  // useEffect(() => {
  //   const intervalId = setInterval(() => {
  //     if (phoneNumberInputRef.current) phoneNumberInputRef.current.focus();
  //   }, 100); 

  //   return () => {
  //     clearInterval(intervalId); 
  //   };
  // }, []);

  const [showQR, setShowQR] = useState(false);
  const [url, setUrl] = useState(''); 

  const showQRcode = (link:string) => {
    // const link = 'https://www.example.com';
    setUrl(link);
    setShowQR(true);
  };

  const handleMouseEnter = () => {
    message.info('Version 1.0');
  };

  return (
    <Spin spinning={loading}>
      <Container>
        {/* <div className="modal-overlay"> */}
        <Modal className="modal" isOpen={isModalOpen} onClose={closeModal}>
          <h1 className="modal-title">Share Options</h1>
          <div className="modal-buttons">
            <button
              className="modal-button"
              onClick={async () => {
                if (!user) {
                  // await onShareProject(SHARE_USER_ID);
                  await ShareProject(SHARE_USER_ID, 1);
                } else {
                  // await onShareProject(user.uid);
                  await ShareProject(user.uid, 1);
                }

              }}>Copy Link</button>
            {/* <button className="modal-button" onClick={async () => {
                if (!user) {
                  // await onShareProject(SHARE_USER_ID);
                  await ShareProject(SHARE_USER_ID, 2);
                } else {
                  // await onShareProject(user.uid);
                  await ShareProject(user.uid, 2);
                }
              }}
            >
              Via Email
            </button> */}
            {/* <button
              className="modal-button"
              onClick={toggleContainer}
            >
              Via SMS
            </button> */}

            <button
              className="modal-button"
              onClick={async () => {
                if (!user) {
                  // await onShareProject(SHARE_USER_ID);
                  await ShareProject(SHARE_USER_ID, 4);
                } else {
                  // await onShareProject(user.uid);
                  await ShareProject(user.uid, 4);
                }

              }}
            >
              Via QRcode
            </button>

          </div>

          {showQR && (
        <div className="modal-buttons">
          <QRCode value={url}  size={128}/>
        </div>
      )}

          {/* {isContainerVisible && (
            <div id="phoneNumberContainer" className="phoneNumberContainer">
              <input
                type="text"
                id="phoneNumberInput"
                className="phoneNumberInput"
                placeholder="Enter 10-digit US Phone Number"
                value={phoneNumber}
                onChange={handleInputChange}
                onBlur={() => phoneNumberInputRef.current.focus()}
                ref={phoneNumberInputRef}
              />
              <button
                id="sendSMSButton"
                className="sendSMSButton"
                onClick={async () => {
                  // const phoneNumber = phoneNumberInput.textContent;

                  // 检查电话号码是否有效，这里可以添加更多的验证逻辑
                  if (/^[0-9]{10}$/.test(phoneNumber)) {
                    // 执行发送短信的操作
                    if (!user) {
                      // await onShareProject(SHARE_USER_ID);
                      await ShareProject(SHARE_USER_ID, 3);
                    } else {
                      // await onShareProject(user.uid);
                      await ShareProject(user.uid, 3);
                    }
                  } else {
                    alert("Please enter a valid 10-digit U.S. phone number.");
                    phoneNumberInputRef.current.focus(); // 无效时重新设置焦点到输入框
                  }
                }}
              >
                Send
              </button>
            </div>
          )} */}

        </Modal>

        {/* @ts-ignore */}
        <ActionContainer gap={2}>
          <a
            href="https://listeningtowaves.com/mflow"
            target="_blank"
            style={{ width: 48 }}
            onMouseEnter={handleMouseEnter}
          >
            <img src={questionBtn} alt="Info" />
          </a>
          {processRunning && (
            <Flex
              minW={12}
              minH={12}
              bgColor="whiteAlpha.500"
              p={2}
              borderRadius={5}
              cursor="pointer"
              onClick={handleProcessBtn}
              id="process-control-btn"
            >
              <Icon
                color="orange.300"
                as={processRunning ? FaStop : FaPlay}
                w="100%"
                h="100%"
              >
                Start
              </Icon>
            </Flex>
          )}

{ user   && ( 
          <Select 
            bg="orange.500"
            color="black"
            width={"150px"}
            maxW={"250px"}
            onChange={onSelectTemplate}
            value={selectedFlow}
          >
            {flowOptions.map((opt) => (
              <option value={opt.value} key={opt.value}>
                {opt.text}
              </option>
            ))}
          </Select>
 )}
          <Button
            minW="72px"
            onClick={async () => {
              if (user) {
                if (confirm("Are you sure you want to logout?")) {
                  signOut();
                  window.location.reload();
                  //add clear the default project under the shared_id or the user would see projects not created by them
                  // setElements([]);
                  // posthog.capture("onClearProject", {
                  //   payload: { flowID: selectedFlow },
                  // });
                  // window.location.reload();
                }
              } else {
                afterAuthCallback.current = async (uid: string) => {
                  // if (selectedFlow === "draft") {
                  //   await onSaveFlow(uid);
                  //   window.location.reload();
                  // }
                  const flowID = await onSaveFlow(uid,true);
                  localStorage.setItem("latestFlow", flowID);
                  window.location.reload();

                };
                setRequiredAuth(true);
              }
            }}

            // onClick={async () => {
            //   if (!user) {
            //     if (
            //       confirm(
            //         "You will need to login to save the project. Do you want to proceed?"
            //       )
            //     ) {
            //       afterAuthCallback.current = async (uid: string) => {
            //         const flowID = await onSaveFlow(uid,true);
            //         localStorage.setItem("latestFlow", flowID);
            //         window.location.reload();
            //       };
            //       setRequiredAuth(true);
            //     }
            //     return;
            //   } else {
            //     const flowID = await onSaveFlow(user.uid,false);
            //     localStorage.setItem("latestFlow", flowID);
            //   }
            // }}

          >
            {user ? "Logout" : "Login"}
          </Button>
          
          <Tooltip
            title={
              selectedFlow === "draft" ? "Save Project" : "Save Project As"
            }
            zIndex={9999}
          >
            <IconButton
              aria-label={
                selectedFlow === "draft" ? "Save Project" : "Save Project As"
              }
              icon={selectedFlow === "draft" ? <FaSave /> : <FaCopy />}
              onClick={async () => {
                // determine if we need to empty local flow
                let previousFlow = selectedFlow;
                if (!user) {
                  if (
                    confirm(
                      "You will need to login to save the project. Do you want to proceed?"
                    )
                  ) {
                    afterAuthCallback.current = async (uid: string) => {
                      console.log("Test");
                      const flowID = await onSaveFlow(uid,true);
                      localStorage.setItem("latestFlow", flowID);

                      if (previousFlow === "draft") {
                        // empty local flow
                        await updateLocalFlow([]);
                      }

                      window.location.reload();
                    };
                    setRequiredAuth(true);
                  }
                  return;
                } else {
                  const flowID = await onSaveFlow(user.uid,false);
                  if (previousFlow === "draft") {
                    // empty local flow
                    await updateLocalFlow([]);
                  }
                  localStorage.setItem("latestFlow", flowID);
                }
              }}
            />
          </Tooltip>
          {selectedFlow !== "draft" &&
            user &&
            user.uid === urlParamsRef.current.userID && (
              <Tooltip title={"Remove Project"} zIndex={9999}>
                <IconButton
                  aria-label="Remove Project"
                  onClick={async () => {
                    if (
                      confirm(
                        "Are you sure you want to remove this project? This cannot be undone."
                      )
                    ) {
                      const urlUserID = urlParamsRef.current.userID;
                      const flowID = urlParamsRef.current.flowID;
                      await removeFlow(
                        selectedFlow === flowID ? urlUserID : user?.uid,
                        selectedFlow
                      );
                      await onLoadFlows(undefined, undefined, false);

                      posthog.capture("onRemoveProject", {
                        payload: { flowID },
                      });
                    }
                  }}
                  icon={<FaTrash />}
                />
              </Tooltip>
            )}
          <Tooltip title={"Clear Screen"} zIndex={9999}>
            <IconButton
              aria-label="Clear Screen"
              onClick={async () => {
                if (
                  confirm(
                    "You are about to remove all the blocks. This is irreversible. Are you sure you want to do that?"
                  )
                ) {
                  // allow empty elements to update local flow
                  window.sessionStorage.setItem("removeToEmpty", "true");
                  setElements([]);
                  posthog.capture("onClearProject", {
                    payload: { flowID: selectedFlow },
                  });
                }
              }}
              icon={<FaEraser />}
            />
          </Tooltip>
          <Tooltip title={"Share Project"} zIndex={9999}>
            <IconButton
              aria-label="Share Project"
              // onClick={async () => {
              //   if (!user) {
              //     if (
              //       confirm(
              //         "You will need to login to share the project. Do you want to proceed?"
              //       )
              //     ) {
              //       afterAuthCallback.current = async (uid: string) => {
              //         // await onShareProject(uid);
              //         setShowQR(false);
              //         setIsModalOpen(true);
              //       };
              //       setRequiredAuth(true);
              //     }
              //     return;
              //   }
              //   // onShareProject(user.uid);
              //   setShowQR(false);
              //   setIsModalOpen(true);
              // }}

              onClick={async () => {
                if (!user) { //not login

                  //create flow ID for shared_uid without asking for a project name， create new id even the project is not a new one
                  const flowID = await onSaveFlow(SHARE_USER_ID,false);
                  copiedURL = `${window.location.origin}/?flowID=${flowID}&userID=${
                    SHARE_USER_ID
                  }`;
                  localStorage.setItem("latestFlow", flowID);
                  console.log("It's created by not login users-" + copiedURL);

                  setShowQR(false);
                  setIsModalOpen(true);
                }else{ // login 
                // onShareProject(user.uid);
                if (selectedFlow === "draft") {
                  // 如果选择的流程为"draft"，则保存流程并获取flowID
                  
                  console.log("this is uid now: "+ user.uid);
                  const flowID = await onSaveFlow(user.uid,false);
                  copiedURL = `${window.location.origin}/?flowID=${flowID}&userID=${
                    user.uid 
                  }`;
                  localStorage.setItem("latestFlow", flowID);
                  console.log("It's DRAFT-" + copiedURL);
                } else {
                  // 如果选择的流程不为"draft"，直接使用选定的flowID和userID拼接URL
                  copiedURL = `${window.location.origin}/?flowID=${selectedFlow}&userID=${
                    user.uid
                  }`;
                  
                  console.log("none Draft-" + copiedURL);
                }

                setShowQR(false);
                setIsModalOpen(true);
                }

              }}

            //   onClick={async () => {
            //     setShowQR(false);
            //     setIsModalOpen(true);
            //   }
            // }
              // onClick={openModal} //liuzifeng 2023/9/24
              icon={<FaShareSquare />}
            />
          </Tooltip>
        </ActionContainer>
        <Dock
          data={flowOptions
            .filter((opt) => opt.value !== "draft")
            .map((opt) => ({
              name: opt.text,
              icon: FaCodepen,
              nodeType: NodeTypes.moduleNode,
              id: opt.value,
            }))}
        />
        <ReactFlowProvider>
          <div ref={reactFlowWrapper} className="reactflow-wrapper">
            <SmartEdgeProvider
              options={{
                debounceTime: 100,
                nodePadding: 15,
                gridRatio: 5,
                lineType: "curve",
                lessCorners: false,
                setElements: setElements,
              }}
            >
              {isMobile ? (
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    width: "100%",
                    height: "100%",
                    background: "#3E3E3E",
                    color: "#131313",
                  }}
                >
                  <Icon
                    className="mobile-play-btn"
                    as={processRunning ? FaStop : FaPlay}
                    color="orange.300"
                    w="100%"
                    h="100%"
                    p={"128px"}
                    onClick={
                      processRunning
                        ? () => onStopFlowProcess(true)
                        : onPlayEntireProcess
                    }
                  />
                </div>
              ) : (
                <ReactFlow
                  elements={elements}
                  onEdgesChange={(_) => console.log("hi")}
                  onSelectionChange={(elms) => {
                    selectedElementsRef.current = elms;
                  }}
                  onElementClick={onElementClick}
                  onElementsRemove={onElementsRemove}
                  onConnect={onConnect}
                  onConnectStart={(e, params) => {
                    startHandleRef.current = params;
                    setHiddenHandles(true);
                  }}
                  onConnectStop={(params) => {
                    const target = params.target as HTMLDivElement;
                    console.log(target.classList);
                    setHiddenHandles(false);
                    if (
                      target.classList.contains("react-flow__handle") &&
                      startHandleRef.current
                    ) {
                      if (
                        target.classList.contains(
                          startHandleRef.current.handleType
                        )
                      ) {
                        const readableHandleType =
                          startHandleRef.current.handleType === "source"
                            ? "Output"
                            : "Input";
                        message.error(
                          `${readableHandleType} cannot be connected with ${readableHandleType}`
                        );
                      }
                    }
                  }}
                  onNodeDragStop={onNodeDragStop}
                  onEdgeContextMenu={(e, edge) => {
                    console.log("hello");
                  }}
                  onLoad={onLoad}
                  onDrop={onDrop}
                  onDragOver={onDragOver}
                  multiSelectionKeyCode={
                    window.navigator.platform.toLowerCase().includes("mac")
                      ? 18
                      : 17
                  }
                  nodeTypes={nodeTypes}
                  edgeTypes={{ smart: SmartEdge }}
                  connectionLineStyle={connectionLineStyle}
                  // connectionLineType={ConnectionLineType.SmoothStep}
                  snapToGrid={true}
                  snapGrid={snapGrid}
                  minZoom={0.5}
                  maxZoom={10}
                  defaultZoom={0.5}
                  arrowHeadColor="#96B38A"
                >
                  <Background
                    variant={BackgroundVariant.Dots}
                    gap={12}
                    size={0.5}
                    style={{ background: "#3E3E3E" }}
                    color="#131313"
                  />
                  <Controls />
                  <PhaseControl />
                  {/* <MiniMap
                  nodeStrokeColor={(n) => {
                    if (n.type === "input") return "#0041d0";
                    if (n.type === "audioNode") return bgColor;
                    if (n.type === "output") return "#ff0072";
                    return "";
                  }}
                  nodeColor={(n) => {
                    if (n.type === "audioNode") return bgColor;
                    return "#fff";
                  }}
                /> */}
                </ReactFlow>
              )}
            </SmartEdgeProvider>
          </div>
        </ReactFlowProvider>
      </Container>
    </Spin>
  );
};

export default withAuth(Home);
