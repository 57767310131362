import React, { memo, useEffect, useMemo, useState } from "react";
import { Handle, Position, NodeProps } from "react-flow-renderer";
import { Flex, Icon, Input, Text } from "@chakra-ui/react";
import { posthog } from "posthog-js";
import { stringify } from 'flatted'

import withPlayBtn from "@/HOCs/withPlayBtn";
import useStore from "@/store/useStore";
import EnhancedHandle from "@/components/enhancedHandle"
import {ref, onDisconnect, serverTimestamp, onValue } from "firebase/database";
import {firebaseDB} from "../services/firebase"

export interface TimeNodeData {
  time?: string;
  onChange: (data: any) => void;
  onPlay?: (id: any) => void;
}

const TimeNode = memo(({ data, isConnectable, id }: NodeProps<TimeNodeData>) => {
  const timeMap = useStore(state => state.timeMap);
  const setTimeMap = useStore((state) => state.setTimeMap);
  // offset is the time difference between the server and the client used to sync time between devices.
  const offsetRef = ref(firebaseDB, ".info/serverTimeOffset");

  // Calculate the time in ms from now to the param time
  const getTimeFromNow = (time: string): number => {
    console.log(`time is ${time}`)
    if (time === undefined || time === null) return 0;
    const [hr, min, sec] = time.split(":");
    const triggerTime = new Date();
    triggerTime.setHours(parseInt(hr), parseInt(min), parseInt(sec));
    let currentTime;
    onValue(offsetRef, (snap) => {
      const offset = snap.val();
      currentTime = new Date().getTime() + offset;
    })
    return triggerTime.getTime() - currentTime;
  }
  // convert time string to number
  const getTimeInNumber = (time:string): number => {
    const [hr, min, sec] = time.split(":");
    const triggerTime = new Date();
    triggerTime.setHours(parseInt(hr), parseInt(min), parseInt(sec));

    return triggerTime.getTime();
  }

  const time = useMemo(() => {
    const storeTime = timeMap.get(id)
    if (storeTime !== undefined) {
      return storeTime
    }
    return data.time
  }, [timeMap, timeMap.get(id)]);

  useEffect(() => {
    setTimeMap({ id, time : data.time });
  }, [data.time]);

  useEffect(() => {
    console.log('init useEffect, time is ', time)
    onChangeTime(time);
  }, []);

  const [countDown, setCountDown] = useState('');
  const [playTime, setPlayTime] = useState(0);

  const onChangeTime = (time: string) => {
    console.log('onChangeTime, time is ', time)
    const timeFromNow = getTimeFromNow(time);
    data.onChange({ time, nodeID: id });
    if (timeFromNow > 0) {
      console.log('timeFromNow > 0 setPlay Time')
      setPlayTime(getTimeInNumber(time));
    }else {
      setCountDown(null);
    }
  };

  //Count Down Timer
  useEffect(() => {
    const intervalId = setInterval(() => {
      //get server time offset
      let offset;
      onValue(offsetRef, (snap) => {
        offset = snap.val();
      });
      const datePlusOffset = new Date().getTime() + offset;

      if( playTime > 0){
        if( playTime <= datePlusOffset){
          //Time is up
          data.onPlay?.({ id });
          setCountDown(null);
          setPlayTime(0);
        }else {
          setCountDown(((playTime - datePlusOffset) / 1000).toFixed(1))
        }
      }
    }, 100);
    return () => clearInterval(intervalId);
  }, [playTime]);

  return (
    <>
      <EnhancedHandle
        type="target"
        position={Position.Left}
        isConnectable={isConnectable}
        id="time-input"
      />
      <Flex h="100%" justify={"center"} align="center" direction={"column"} p={3}>
        <Text mb={1}>
          {`Play at`}
        </Text>
        <Input
          defaultValue={time||''}
          type="time"
          step="1"
          className="nodrag"
          size="m"
          width='-moz-fit-content'
          textAlign='center'
          onChange={(e) => onChangeTime(e.target.value)}
        />
        <Text mb={1}>
          {countDown? `Count Down: ${countDown}` : 'Time Passed'}
        </Text>
      </Flex>
      <EnhancedHandle
        type="source"
        position={Position.Right}
        isConnectable={isConnectable}
        id={`time-output-${id}`}
      />
    </>
  );
});

export default withPlayBtn(TimeNode)
