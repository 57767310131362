import React, { memo, useMemo, useEffect, useRef, useState } from "react";
import { Handle, Position, NodeProps, Node } from "react-flow-renderer";
import { Flex, Input, Text } from "@chakra-ui/react";
import styled from "@emotion/styled";
import { message } from 'antd'
import { posthog } from "posthog-js";
import { stringify } from 'flatted'

import EnhancedHandle from "@/components/enhancedHandle";
import playBtnSvg from "@/assets/play.svg";
import NodeTrashButton from "@/components/nodeTrashButton";
import HiddenHandle from "./hiddenHandle";
const Container = styled(Flex)`
  .chakra-text {
    font-size: 0.7em;
    padding: 0 0 8px 0;
    align-self: flex-start;
    
  }

  .chakra-input {
    height: 26px;
    font-size: 1em;
    text-align: center;
    text-align: center;
    width: 210px;
  }

  .chakra-input::placeholder {
    color: rgba(255, 255, 255, 0.5);
    // font-size: 14px;
  }
  .hiddenHandle {
    //pointer-events:none;
    width: 0%;
    height: 0%;
    position: absolute;
    top: 0;
    left: 0px !important;
    border-radius: 0;
    transform: none;
    border: none;
    opacity: 0;
    cursor: pointer !important;
    display: block;
  }
  .play-node-icon {
    &:hover{
      z-index:1;
      cursor: pointer;
    }
  }
`;
export interface PlayNodeData {
  keyBinding: string;
  order: number; // 添加了order属性
  onChange: (data: any) => void;
  onPlay: (node: Partial<Node>) => void;
  onStop: (hardStop: boolean) => void;
  onRemove: (ele: any) => void;
}

const PlayNode = memo(
  ({ data, isConnectable, id ,order, ...rest}: NodeProps<PlayNodeData>) => {
    const keybindings = useMemo(() => {
      const _temp = [];
      if (data.keyBinding) {
        for (let i = 0; i < data.keyBinding.length; i++) {
          const char = data.keyBinding.charAt(i);
          if (char !== " ") {
            _temp.push(char.toLowerCase());
          }
        }
      }
      return _temp;
    }, [data.keyBinding]);

    const keybindingsRef = useRef(keybindings)
    const readyRef = useRef(true)
    
    useEffect(() => {
      return () => {
        document.body.removeEventListener('keydown', onPlay)
      }
    }, [])

    useEffect(() => {
      keybindingsRef.current = keybindings
      document.body.removeEventListener('keydown', onPlay)
      document.body.addEventListener('keydown', onPlay)
    }, [keybindings])

    const onPlay = (e) => {
      if (keybindingsRef.current.includes(e.key.toLowerCase())) {
        if (!readyRef.current) {
          return
        }
        readyRef.current = false

        data.onPlay?.({ id })
        posthog.capture('onPlayButtonTriggered', { 
          payload: stringify({ nodeID: id, keyBinding: data.keyBinding })
        })

        setTimeout(() => {
          readyRef.current = true
        }, 100)
      }
    }
    const onRemoveNode: React.MouseEventHandler<HTMLDivElement> = (e) => {
      data.onRemove?.([{ id, ...rest}]);
    };
    // 基于order计算字母
    const letter = useMemo(() => {
    return String.fromCharCode(65 + data.order % 26); // A开始，循环回A
    }, [data.order]);

    // const [inputValue, setInputValue] = useState(data?.keyBinding || letter);
    const [inputValue, setInputValue] = useState(data?.keyBinding);
    useEffect(() => {
      if (!data?.keyBinding) {
        data.onChange?.({ nodeID: id, keyBinding: letter });
        setInputValue(letter);
      }
    }, []); // 只在组件挂载时运行

    return (
      <>
        <EnhancedHandle
          type="target"
          position={Position.Left}
          isConnectable={isConnectable}
          id={`play-input-${id}`}
        />
        <Container
          h="100%"
          justify={"center"}
          align="center"
          direction={"column"}
          p={2}
        >
        {/* <Text fontSize='20px !important'>{`${letter}`}</Text> */}
        {/* <Text fontSize='20px !important'>{inputValue}</Text> */}
        <Handle
          className="hiddenHandle"
          position={Position.Left}
          type="target"
        />
          <img
            className="nodrag play-node-icon"
            width={"30%"}
            src={playBtnSvg}
            alt="play button"
            onClick={() => data.onPlay?.({ id })}
          />
          <Text>Play when you press :</Text>
          <Input
                placeholder="Letter"
                value={inputValue}
                onChange={(e) => {
                  const newValue = e.target.value.charAt(e.target.value.length - 1); // 获取最后一个字母
                  setInputValue(newValue); // 更新输入值
                  if (e.target.value.length > 1) {
                    message.error('Only one key is allowed.');
                  }
                  const newKeyBinding = newValue || letter; // 当输入值为空时，使用 letter
                  data.onChange?.({ nodeID: id, keyBinding: newKeyBinding }); // 使用最后一个字母或 letter 作为 keyBinding 的值
                }}
                style={{ width: 'auto' }} // 设置宽度自适应
              />

        </Container>
        <NodeTrashButton onClick={onRemoveNode} />
        <EnhancedHandle
          type="source"
          position={Position.Right}
          isConnectable={isConnectable}
          id={`play-output-${id}`}
        />
      </>
    );
  }
);

export default PlayNode;
