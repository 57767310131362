import create from "zustand";
import { persist } from "zustand/middleware";
import { devtools } from "zustand/middleware";
import { Node, Edge } from "react-flow-renderer";
import produce, { enableMapSet } from "immer";
import { Howl } from "howler";

import { AudioNodeProgress } from "../lib/types";

export interface StoreState {
  processID?: string;
  currIntervalID?: string;
  activeNode?: Map<string, Node>;
  activeEdge?: Map<string, Partial<Edge>>;
  audioNodeProgress?: Map<string, AudioNodeProgress>;
  loopMap?: Map<string, number>;
  switchMap?: Map<string, Set<number>>;
  audioCache: Map<string, Howl>;
  selectedPhase: number;
  probDistMap?: Map<string, number>;
  intervalMap?: Map<string, number>;
  timeMap?: Map<string, string>;
  cntDownMap: Map<string, number>;
  zeroCountMap?: Map<string, number>; // 添加新字段

  addActiveNode: (node: Node) => void;
  removeActiveNode: (node: Node) => void;
  addActiveEdges: (edges: Partial<Edge>[]) => void;
  removeActiveEdges: (edges: Partial<Edge>[]) => void;
  setAudioNodeProgress: (data: AudioNodeProgress | null) => void;
  setLoopCount: (data: { loop: number; id: string }) => void;
  setSwitchMap: (data: { switch: Set<number>; id: string }) => void;
  setTimeMap: (data: { time: string; id: string }) => void;
  setCntDownMap: (data: { cntDown: number; id: string }) => void;
  addAudioToCache: (audioSrc: string) => void;
  removeAudioFromCache: (audioSrc: string) => void;
  setProcessID: (id: string) => void;
  setPhase: (phase: StoreState["selectedPhase"]) => void;
  setProbDistMap: (data: { probDist: number; id: string }) => void;
  setIntervalMap: (data: {interval: number; id: string }) => void;
  setCurrIntervalID: (nodeId: string) => void;

}

enableMapSet();

const useStore = create<StoreState>(
  persist(
    devtools(
      (set) => ({
        zeroCountMap: new Map(), // new by ZF
        activeNode: new Map(),
        activeEdge: new Map(),
        audioNodeProgress: new Map(),
        loopMap: new Map(),
        switchMap: new Map(),
        probDistMap: new Map(),
        intervalMap: new Map(),
        timeMap: new Map(),
        cntDownMap: new Map(),
        audioCache: new Map(
          Object.entries({
            "/original.m4a": new Howl({
              src: "/original.m4a",
              format: ["webm", "m4a", "mp3"],
              //html5:true,
            }),
            "/4th.m4a": new Howl({
              src: "/4th.m4a",
              format: ["webm", "m4a", "mp3"],
              //html5:true,
            }),
            "/12th.mp3": new Howl({
              src: "/12th.mp3",
              format: ["webm", "m4a", "mp3"],
              //html5:true,
            }),
          })
        ),
        selectedPhase: 1,
        addActiveNode: (node) =>
          set(
            produce((state: StoreState) => {
              state.activeNode.set(node.id, JSON.parse(JSON.stringify(node)));
            })
          ),
        removeActiveNode: (node) =>
          set(
            produce((state: StoreState) => {
              state.activeNode.delete(node.id);
            })
          ),
        addActiveEdges: (edges) =>
          set(
            produce((state: StoreState) => {
              edges.forEach((edge) => {
                state.activeEdge.set(edge.target, edge);
              });
            })
          ),
        removeActiveEdges: (edges) =>
          set(
            produce((state: StoreState) => {
              edges.forEach((edge) => {
                state.activeEdge.delete(edge.target);
              });
            })
          ),
        setAudioNodeProgress: (data) => {
          if (data === null) {
            set({ audioNodeProgress: null });
          } else {
            set(
              produce((state: StoreState) => {
                state.audioNodeProgress.set(data.id, data);
              })
            );
          }
        },
        
        setLoopCount: (data) => {
          // set(
          //   produce((state: StoreState) => {
          //     if (data.loop!==0 ) { //!==
          //       state.loopMap.set(data.id, data.loop);
          //       console.log("loop time in the produce is: ", data.loop)
          //     }
          //     else {
          //       console.log("loop time in else: ", data.loop)
          //       //state.loopMap.set(data.id, 0);
          //       state.loopMap.delete(data.id);
          //     }
          //   })
          // );
          set(
            produce((state: StoreState) => {
              if (data.loop !== 0) {
                // 如果 loop 不为 0，更新 loopMap 并重置 zeroCountMap 中的计数
                state.loopMap.set(data.id, data.loop);
                state.zeroCountMap.set(data.id, 0);
                console.log("loop time in the produce is: ", data.loop);
              } else {
                // 如果 loop 为 0
                console.log("loop time in else: ", data.loop);
                state.loopMap.set(data.id, 0);
                const zeroCount = (state.zeroCountMap.get(data.id) || 0) + 1;
                state.zeroCountMap.set(data.id, zeroCount);
                // 如果这是第二次 loop 为 0
                if (zeroCount === 2) {
                  state.loopMap.delete(data.id);
                  state.zeroCountMap.delete(data.id); // 也可以选择保留计数
                }
              }
            })
          );

        },
        setSwitchMap: (data) => {
          set(
            produce((state: StoreState) => {
              state.switchMap.set(data.id, data.switch);
            })
          );
        },
        setTimeMap: (data) => {
          set(
            produce((state: StoreState) => {
              state.timeMap.set(data.id, data.time);
            })
          );
        },
        setCntDownMap: (data) => {
          set(
            produce((state: StoreState) => {
              state.cntDownMap.set(data.id, data.cntDown);
            })
          );
        },
        addAudioToCache: (src) =>
          set(
            produce((state: StoreState) => {
              const audio = state.audioCache.get(src);
              if (!audio) {
                state.audioCache.set(
                  src,
                  new Howl({
                    src,
                    format: ["webm"], // webm lzf changed on 11-20
                    //html5:true,
                  })
                );
              }
            })
          ),
        removeAudioFromCache: (src) =>
          set(
            produce((state: StoreState) => {
              state.audioCache.delete(src);
            })
          ),
        setProcessID: (id) => {
          set(
            produce((state) => {
              if (state.processID && state.processID !== id) {
                // clean up and reset
                state.activeNode = new Map();
                state.audioNodeProgress = new Map();
              }

              state.processID = id;
            })
          );
        },
        setPhase: (phase) => {
          set(
            produce((state) => {
              state.selectedPhase = phase;
            })
          );
        },
        setProbDistMap: (data) => {
          set(
            produce( (state: StoreState) => {
              state.probDistMap.set(data.id, data.probDist);
            })
          );
        },
        setIntervalMap: (data) => {
          set(
            produce( (state: StoreState) => {
              state.intervalMap.set(data.id, data.interval);
            })
          );
        },
        setCurrIntervalID: (id) => {
          set(
            produce( (state: StoreState) => {
              state.currIntervalID = id;
            })
          )
        },

      }),
      { name: "MyStore", serialize: { options: true } }
    ),
    {
      name: "mflow",
      partialize: (s) => {
        const { selectedPhase } = s;
        return {
          selectedPhase,
        };
      },
    }
  )
);

export default useStore;
