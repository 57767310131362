import React, { memo, useMemo, useEffect, useRef, useCallback } from "react";
import {
  Handle,
  Position,
  NodeProps,
  useUpdateNodeInternals,
} from "react-flow-renderer";
import { Flex, Text, Switch, Icon } from "@chakra-ui/react";
import { AiOutlinePlusSquare, AiOutlineMinusSquare } from "react-icons/ai";
import styled from "@emotion/styled";
import { posthog } from "posthog-js";
import { stringify } from 'flatted'

import withPlayBtn from "@/HOCs/withPlayBtn";
import useStore from "@/store/useStore";
import EnhancedHandle from "@/components/enhancedHandle"

export interface RandomNodeData {
  onChange: (data: any) => void;
  branchNum?: number;
  selectedSwitches?: number[];
}

const StyledIcon = styled(Icon)`
  width: 20%;
  height: auto;
  transition: color 0.3s;
  cursor: pointer;
  &:hover {
    z-index: 1;
    color: var(--chakra-colors-blackAlpha-700);
  }
`;

const KEY_CODES = [
  {
    letter: "Q",
    keycode: 81,
  },
  {
    letter: "W",
    keycode: 87,
  },
  {
    letter: "E",
    keycode: 69,
  },
  {
    letter: "A",
    keycode: 65,
  },
  {
    letter: "S",
    keycode: 83,
  },
  {
    letter: "D",
    keycode: 68,
  },
];

const SwitchNode = memo(
  ({ data, isConnectable, id, selected }: NodeProps<RandomNodeData>) => {
    const setSwitchMap = useStore((state) => state.setSwitchMap);

    const branchNum = useMemo(() => {
      console.log(`branchNum: branNum is ${data.branchNum}}`)

      return data.branchNum ?? 2;
    }, [data.branchNum]);

    const selectedSwitches = useMemo(() => {
      return new Set(data.selectedSwitches ?? [0]);
    }, [data.selectedSwitches]);

    const selectedSwitchesRef = useRef<Set<number>>();
    const branchNumRef = useRef<number>();

    const getXPos = (index: number) => {
      return (100 / branchNum) * index + 100 / branchNum / 2 - 8;
    };

    const onChangeBranchNum = (branchNum: number) => {
      branchNum = Math.min(Math.max(branchNum, 1), 5);
      console.log(`onChangeBranchNum: branNum is ${data.branchNum}}`)

      data.onChange({ branchNum, nodeID: id });

      console.log(`onChangeBranchNumAfter: branNum is ${data.branchNum}}`)

      posthog.capture('onChangeBranchNum', {
        payload: stringify({ nodeID: id, branchNum }),
      })
    };

    useEffect(() => {
      if (data.selectedSwitches === undefined) {
        data.onChange({
          selectedSwitches: [...selectedSwitches],
          nodeID: id,
        });
      }

      return () => {
        document.removeEventListener("keydown", onKeyPress, true);
      }
    }, []);

    useEffect(() => {
      selectedSwitchesRef.current = selectedSwitches;
      setSwitchMap({ id, switch: selectedSwitches });

      posthog.capture('onChangeActiveSwitches', {
        payload: stringify({ nodeID: id, selectedSwitches }),
      })
    }, [selectedSwitches]);

    useEffect(() => {
      branchNumRef.current = branchNum;
    }, [branchNum]);

    const onKeyPress = useCallback((e) => {
      [...Array(branchNumRef.current).keys()].map((key) => {
        const currentKeyCode = KEY_CODES[key].keycode;
        if (e.keyCode === currentKeyCode) {
          const switches = new Set(selectedSwitchesRef.current);
          if (switches.has(key)) {
            switches.delete(key);
          } else {
            switches.add(key);
          }
          data.onChange({
            selectedSwitches: [...switches],
            nodeID: id,
          });
        }
      });
    }, []);

    useEffect(() => {
      if (selected) {
        document.addEventListener("keydown", onKeyPress, true);
      } else {
        document.removeEventListener("keydown", onKeyPress, true);
      }
    }, [selected]);

    const updateNodeInternals = useUpdateNodeInternals();

    // https://github.com/wbkd/react-flow/issues/1305
    useEffect(() => {
      updateNodeInternals(id);
    }, [branchNum]);

    return (
      <>
        <EnhancedHandle
          type="target"
          position={Position.Left}
          isConnectable={isConnectable}
          id="switch-input"
        />
        <Flex
          h={"100%"}
          align="space-start"
          justify={"center"}
          direction="column"
          px={4}
          pt={1}
        >
          <Text mb={1} fontSize='md'>{`Switch Box`}</Text>
          <Flex>
            <StyledIcon
              as={AiOutlineMinusSquare}
              onClick={() => onChangeBranchNum(branchNum - 1)}
            />
            <StyledIcon
              as={AiOutlinePlusSquare}
              onClick={() => onChangeBranchNum(branchNum + 1)}
            />
          </Flex>
        </Flex>
        {[...Array(branchNum).keys()].map((key) => {
          const isOn = selectedSwitches.has(key);
          return (
            <Flex
              gap={"4px"}
              key={key}
              style={{
                position: "absolute",
                top: `${getXPos(key)}%`,
                right: "-8px",
              }}
              align="center"
              className="nodrag"
            >
              {selected && <Text fontSize="xs">[{KEY_CODES[key].letter}]</Text>}
              <Text fontSize="xs">{isOn ? "On" : "Off"}</Text>
              <Switch
                onChange={() => {
                  const updatedSelectedSwitches = new Set(selectedSwitches)
                  updatedSelectedSwitches.has(key)
                    ? updatedSelectedSwitches.delete(key)
                    : updatedSelectedSwitches.add(key);
                  data.onChange({
                    selectedSwitches: [...updatedSelectedSwitches],
                    nodeID: id,
                  });
                }}
                isChecked={isOn}
                size="sm"
              />
              <EnhancedHandle
                annotation={`OUT_${key + 1}`}
                annotationStyle={{ right: -70, bottom: -6 }}
                type="source"
                position={Position.Right}
                isConnectable={isConnectable}
                style={{ position: "relative", right: 0, transform: "none" }}
                id={`${id}_${key}`}
              />
            </Flex>
          );
        })}
      </>
    );
  }
);

export default withPlayBtn(SwitchNode);
