import { FFmpeg } from "ffmpeg-wasm-patched";
import { toBlobURL } from "@ffmpeg/util";

let ffmpeg: FFmpeg;
let loadPromise: Promise<any>;
let loadCallbacks: any;

/**
 * 加载FFmpeg WASM
 * 没办法了，我得针对这个项目搞一个补丁包
 * @returns {Promise} - 加载promise
 */
export const load = async () => {
    // 如果有加载中的promise则等待promise completed
    if(loadPromise)
        return await loadPromise;
    // 实例化一个Promise，设置一个回调用来暂存resolve和reject
    loadPromise = new Promise((resolve, reject) => loadCallbacks = {resolve, reject});
    const baseURL = "https://unpkg.com/@ffmpeg/core@0.12.4/dist/esm";
    ffmpeg = new FFmpeg();
    (async () => {
        await ffmpeg.load({
            // 加载ffmpeg核心脚本
            coreURL: await toBlobURL(`${baseURL}/ffmpeg-core.js`, "text/javascript"),
            // 加载ffmpeg WASM模块
            wasmURL: await toBlobURL(`${baseURL}/ffmpeg-core.wasm`, "application/wasm")
        })
    })()
    // 当ffmpeg加载完成时调用resolve或reject
    .then(loadCallbacks.resolve)
    .catch(loadCallbacks.reject);
    // 返回加载promise
    return loadPromise;
}

/**
 * 转码方法
 * 
 * @param buffer - ArrayBuffer
 * @param sourceFormat - 来源媒体格式
 * @param targetFormat - 目标媒体格式
 * @param bitrate - 媒体码率
 * @returns {Uint8Array} - 转码后的数据
 */
export const transcode = async (buffer, sourceFormat, targetFormat, bitrate = 192) => {
    // 写入源文件数据到虚拟的文件系统
    await ffmpeg.writeFile(`input.${sourceFormat}`, new Uint8Array(buffer));
    // 在虚拟的文件系统中执行ffmpeg的转码指令 与ffmpeg的命令一样：ffmpeg -i input.webm -b:a 192k output.mp3
    await ffmpeg.exec(["-i", `input.${sourceFormat}`, "-b:a", `${bitrate}k`, `output.${targetFormat}`]);
    // 从虚拟的文件系统中读取output.mp3到数据，获得Uint8Array数据
    const data = await ffmpeg.readFile(`output.${targetFormat}`);
    return data as Uint8Array;
}