import React, { useEffect, useMemo, useState } from "react";
import { Handle, NodeProps, Position } from "react-flow-renderer";

import NodePlayButton from "@/components/nodePlayButton";
import NodeTrashButton from "@/components/nodeTrashButton";
//import './temp.css'
import styled from "@emotion/styled";
import useStore from "@/store/useStore";
import { Button } from "antd";

export default (Child: any) => {
  const Container = styled.div`
    .hiddenHandle {
      //pointer-events:none;
      width: 0%;
      height: 0%;
      position: absolute;
      top: 0;
      left: 0px !important;
      border-radius: 0;
      transform: none;
      border: none;
      opacity: 0;
      cursor: pointer !important;
      display: block;
    }
    `;

  return ({ id, data, ...rest }: NodeProps) => {
    const onPlayNode: React.MouseEventHandler<HTMLDivElement> = (e) => {
      console.log("play node", data)
      data.onPlay?.({ id });
    };

    const onRemoveNode: React.MouseEventHandler<HTMLDivElement> = (e) => {
      data.onRemove?.([{ id, ...rest }]);
    };

    return (
      <Container>
        <Handle
            className="hiddenHandle"
            position={Position.Left}
            type="target"
        />
        <Child id={id} data={data} {...rest} />

        <NodePlayButton onClick={onPlayNode} />
        <NodeTrashButton onClick={onRemoveNode} />
      </Container>
    );
  };
};