import { Node, Edge } from "react-flow-renderer";

import { FlowTreeEvents, AudioNodeProgress } from "../lib/types";

class FlowEventTarget extends EventTarget {
  onAddActiveNode(node: Node) {
    this.dispatchEvent(
      new CustomEvent<Node>(FlowTreeEvents.AddActiveNode, {
        detail: node,
      })
    );
  }

  onAddActiveEdge(edge: Partial<Edge>[]) {
    this.dispatchEvent(
      new CustomEvent<Partial<Edge>[]>(FlowTreeEvents.AddActiveEdge, {
        detail: edge,
      })
    );
  }

  onRemoveActiveNode(node: Node) {
    this.dispatchEvent(
      new CustomEvent<Node>(FlowTreeEvents.RemoveActiveNode, {
        detail: node,
      })
    );
  }

  onRemoveActiveEdge(edge: Partial<Edge>[]) {
    this.dispatchEvent(
      new CustomEvent<Partial<Edge>[]>(FlowTreeEvents.RemoveActiveEdge, {
        detail: edge,
      })
    );
  }

  onUpdateAudioNodeProgress(data: AudioNodeProgress | null) {
    this.dispatchEvent(
      new CustomEvent<AudioNodeProgress>(FlowTreeEvents.UpdateAudioNodeProgress, {
        detail: data,
      })
    );
  }

  onProcessEnd() {
    this.dispatchEvent(
      new CustomEvent<AudioNodeProgress>(FlowTreeEvents.ProcessEnd)
    );
  }
  
  onUpdateLoopCount(data: { loop: number, id: string } | null) {
    this.dispatchEvent(
      new CustomEvent<{ loop: number, id: string }>(FlowTreeEvents.UpdateLoopCount, {
        detail: data,
      })
    );
  }
}

export default FlowEventTarget