import React from 'react'
import styled from '@emotion/styled'
import { Box } from '@chakra-ui/react'

import trashBtnSvg from '@/assets/trash.svg';

const Container = styled(Box)`
    position: absolute;
    height: 38px;
    top: -55px;
    right: 0px;
    cursor: pointer;
    z-index: 99999;
    
    img {
        height: 150%;
        width: auto;
    }
`

export interface NodePlayButtonProps {
    onClick: React.MouseEventHandler<HTMLDivElement>
}

export default ({ onClick }: NodePlayButtonProps) => {
    return (
        <Container onClick={onClick} className='nodrag node-button' draggable={false}>
            <img src={trashBtnSvg} alt="play button" />
        </Container>
    )
}