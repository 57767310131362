import React, { memo, useState, useMemo, useEffect, useRef } from "react";
import { Position, NodeProps, Node } from "react-flow-renderer";
import {
  Box,
  Button,
  VStack,
  Flex,
  IconButton,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
  HStack,
  CircularProgress,
  Center,
} from "@chakra-ui/react";
import { getColor } from "@chakra-ui/theme-tools";
import { ReactMic, ReactMicProps, ReactMicStopEvent } from "react-mic";
import styled from "@emotion/styled";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faRecordVinyl,
  faStop,
  faMicrophone,
} from "@fortawesome/free-solid-svg-icons";
import { Howl } from "howler";
import { posthog } from "posthog-js";
import { stringify } from "flatted";

import theme from "@/theme";
import { NodeTypes } from "@/lib/types";
import useStore from "@/store/useStore";
import soundEffects from "@/fixture/soundEffects";
import withPlayBtn from "@/HOCs/withPlayBtn";
import EnhancedHandle from "@/components/enhancedHandle";
import { size } from "polished";
import { darken } from "polished";
import { blob } from "node:stream/consumers";
import {
  load as loadFFmpeg,
  transcode as transcodeFFmpeg,
} from "@/services/mediaTranscode";
import { FFmpeg } from "@ffmpeg/ffmpeg"; //现在只能上它了
import { toBlobURL } from "@ffmpeg/util";

export interface AudioNodeData extends ReactMicStopEvent {
  onChange: (ReactMicStopEvent) => void;
  onPlay: (node: Partial<Node>) => void;
}

const StyledModalContent = styled(ModalContent)`
  .sound-wave {
    width: 100%;
    height: 148px;
  }
`;

const StyledProgress = styled(CircularProgress)`
  position: absolute;
  top: calc(50% - 36px - 4px);
  left: calc(50% - 39px + 4px);
  & svg {
    width: 78px;
    height: 78px;
  }
`;

const StyledIconButton = styled(IconButton)`
  &:hover {
    color: #53644d !important ;
  }
`;

let TIMER;

interface CustomAudioData extends ReactMicStopEvent {
  duration?: number;
}

const AudioNode = memo(
  ({ data, isConnectable, id }: NodeProps<AudioNodeData>) => {
    // TODO: remove the random sound effects for testing purposes
    const [audioData, setAudioData] = useState<ReactMicStopEvent>({
      blobURL: data.blobURL ?? "/robot_to_record.mp3",
    });
    const [recording, setRecording] = useState<boolean>();
    const { isOpen, onOpen, onClose } = useDisclosure();
    const [isRecordingCompleted, setIsRecordingCompleted] = useState(false); // to control the Recoding text, added by zf
    // const [totalRecordingTime, setTotalRecordingTime] = useState(0);// to control the recording time, added by zf
    // const [totalRecordingTime, setTotalRecordingTime] = useState(3000); // 总录音时长为3秒，以毫秒为单位
    // const [progress, setProgress] = useState(0); // 录音进度，初始为0%
    // const [currentRecordingTime, setCurrentRecordingTime] = useState(0);

    const audioContextRef = useRef(null);
    const recorderRef = useRef(null);

    const activeNode = useStore((state) => state.activeNode);
    const audioNodeProgress = useStore((state) => state.audioNodeProgress);
    const addAudioToCache = useStore((state) => state.addAudioToCache);
    const removeAudioFromCache = useStore(
      (state) => state.removeAudioFromCache
    );

    const isActive = useMemo(() => {
      return Array.from(activeNode.values())
        .map((n) => n.id)
        .includes(id);
    }, [activeNode]);

    const onStartRecording = () => {
      // setTotalRecordingTime(3000); // 设置总录音时长为3秒
      // setIsRecording(false); // 设置初始状态为 false 表示录音未开始
      //   setProgress(0); // 开始录音时，将进度重置为0
      //   const recordingTime = 3 * 1000; // 3秒，以毫秒为单位
      // const interval = 100; // 更新进度的间隔，可以根据需要调整
      // const steps = recordingTime / interval; // 计算总步数
      // let currentStep = 0;
      // const progressTimer = setInterval(() => {
      //   setProgress((currentStep / steps) * 100);
      //   currentStep++;
      //   if (currentStep >= steps) {
      //     clearInterval(progressTimer);
      //     setIsRecording(false); // 录音结束后设置为 false
      //     setProgress(100); // 将进度设置为100%
      //   }
      // }, interval);
      // <StyledProgress value={progress} thickness={"4px"} id="progress-bar" />
      // setIsRecordingCompleted(false);
      // setRecording(false); // 设置初始状态为 false 表示录音未开始
      // setIsRecordingCompleted(false); // 设置录音完成状态为 false
    };

    const currentProgress = useMemo(() => {
      const value = Math.min(audioNodeProgress.get(id)?.progress * 100, 100);
      // console.log("The calculated value: "+value);
      // console.log(audioData.stopTime-audioData.startTime);
      return isNaN(value) ? 0 : value > 50 ? value + 10 : value;
    }, [audioNodeProgress]);

    //   const [loaded, setLoaded] = useState(false);
    //   const ffmpegRef = useRef(new FFmpeg());

    //   const load = async () => {
    //     const baseURL = 'https://unpkg.com/@ffmpeg/core@0.12.4/dist/esm'
    //     const ffmpeg = ffmpegRef.current;
    //     ffmpeg.on('log', ({ message }) => {
    //         console.log(message);
    //     });
    //     await ffmpeg.load({
    //       coreURL: await toBlobURL(`${baseURL}/ffmpeg-core.js`, 'text/javascript'),
    //       wasmURL: await toBlobURL(`${baseURL}/ffmpeg-core.wasm`, 'application/wasm'),
    //   });
    //   setLoaded(true);
    // }
    // 对代码进行整理和封装 然后才算完成

    const requestMicrophoneAccess = async () => {
      try {
        // 请求麦克风访问权限
        const stream = await navigator.mediaDevices.getUserMedia({
          audio: true,
        });
        // 权限被授予，可以继续后续逻辑，例如开始录音
        console.log("Microphone access granted");
        onOpen();
      } catch (error) {
        // 用户拒绝权限或其他错误
        console.error("Microphone access denied", error);
      }
    };

    // // below is for encoding the audio from webm to mp3
    //   const onStopRecording: ReactMicProps["onStop"] = async (audioData) => {
    //     console.log('time is :',new Date().getTime())
    //     // audioData.blob已经决定它的格式是weba，webm是视频，weba是音频，只是通常来说经常会把webm容器也来装音频
    //     const buffer = await audioData.blob.arrayBuffer();
    //     // FFmpeg加载
    //     await loadFFmpeg();
    //     // FFmpeg转码，把audio/webm -> audio/mp3，如果希望录制音频质量更高可以调整码率，128 / 192 / 320 kbps越高越清晰（更耗费性能），但这还取决于react-mic的码率瓶颈，react-mic非VIP版本只有最低质量，那么已经限制了128kbps
    //     const data = await transcodeFFmpeg(buffer, "webm", "mp3", 128);

  //     console.log(data);
  //     // 将buffer封装到blob设定类型为audio/mp3，不设置的话有时会采用系统默认MIME类型 比如 application/octet-stream ，这里指定类型只是为了浏览器识别文件是mp3，这样下载时才会是mp3后缀，它本质不改变数据和编码，一个无类型的blob浏览器是看不出他是啥的
  //     const blob = new Blob([data.buffer], { type: "audio/mp3" });
  //     // 对了，昨天还有个问题没解决，@ffmpeg/ffmpeg引入的new URL问题，这个在vite+vue环境没有复现，我需要先移除你的node_modules并重新安装尝试
  //     const blobURL = window.URL.createObjectURL(blob);
  //     console.log(blob, blobURL)
  //     // 更新状态和缓存
  //     setAudioData({
  //       ...audioData,
  //       blob,
  //       blobURL
  //     });

    //   addAudioToCache(blobURL);
    //  // setAudioData(audioData);
    //  // addAudioToCache(audioData.blobURL);
    //  // removeAudioFromCache(data.blobURL);

    //   // // data.onChange({ nodeID: id, ...audioData });
    //   // // 将提示文本更新为 "Voice Recorded"
    //   setIsRecordingCompleted(true);
    //   console.log('time is :',new Date().getTime())
    // };

    const onStopRecording: ReactMicProps["onStop"] = async (audioData) => {
      console.log("recordedBlob is: ", audioData.blob);
      const mp3 = await convertBlobToMp3(audioData.blob);
      audioData.blobURL = mp3.blobUrl;
      audioData.blob = mp3.blob;
      setAudioData(audioData);
      addAudioToCache(audioData.blobURL);
      removeAudioFromCache(data.blobURL);
      // data.onChange({ nodeID: id, ...audioData });
    };

    const convertBlobToMp3 = async (blob: Blob) => {
      const buffer = await blob.arrayBuffer();
      await loadFFmpeg();
      const data = await transcodeFFmpeg(buffer, "webm", "mp3", 128);
      const mp3Blob = new Blob([data.buffer], { type: "audio/mp3" });
      const blobURL = window.URL.createObjectURL(mp3Blob);
      return {blob: mp3Blob, blobUrl: blobURL};
    }

    useEffect(() => {
      // preload audio
      if (audioData.blobURL) {
        posthog.capture("onRecordAudio", {
          payload: stringify({ nodeID: id }),
        });
      }
      data.onChange({ nodeID: id, ...audioData });
    }, [audioData]);

    return (
      <>
        <EnhancedHandle
          type="target"
          position={Position.Left}
          isConnectable={isConnectable}
          id="audio-input"
        />
        <Flex
          h="100%"
          direction={"column"}
          align="center"
          justify={"center"}
          p={3}
        >
          <Box pb={3}>{isActive ? "Active" : "Record Your Audio"}</Box>

          <VStack
            w="-moz-fit-content"
            h="100%"
            justify={"center"}
            align={"center"}
          >
            <HStack
              w="-moz-fit-content"
              h="100%"
              justify="center"
              style={{ position: "relative" }}
            >
              {isActive && (
                <StyledProgress
                  value={currentProgress}
                  thickness={"4px"}
                  id="progress-bar"
                /> //add id by zf
              )}
              <IconButton
                w="-moz-fit-content"
                h="100%"
                aria-label="record/stop"
                variant="ghost"
                fontSize="48px"
                onClick={requestMicrophoneAccess} // zf changed it from onOpen to requestMicrophoneAccess
                colorScheme={getColor(theme, "whiteAlpha.800")}
                //_hover={{ color: 'red'  }} //change color of the hovered icon
                className="nodrag"
                icon={
                  <Center
                    _hover={{ transform: "scale(1.5)" }}
                    h="100%"
                    w="100%"
                  >
                    <FontAwesomeIcon icon={faMicrophone} />
                  </Center>
                }
              />
            </HStack>
          </VStack>
          <Modal isOpen={isOpen} onClose={onClose}>
            <ModalOverlay />
            <StyledModalContent>
              <ModalHeader>Recorder</ModalHeader>
              <Box
                pb={4}
                color={
                  isRecordingCompleted ? "green" : recording ? "red" : "black"
                }
                textAlign="center"
                mx="auto"
              >
                {isRecordingCompleted ? "" : recording ? "" : ""}
              </Box>

              <ModalCloseButton />
              <ModalBody>
                <VStack spacing={3}>
                  <ReactMic
                    record={recording}
                    className="sound-wave"
                    // mimeType="audio/webm"
                    // channelCount={1} // the two line added for audio coding transfer
                    onStop={onStopRecording}
                    strokeColor="#2E5B7F"
                    backgroundColor="#333333"
                  />
                  <HStack w="100%" justify="center">
                    <IconButton
                      aria-label="record/stop"
                      variant="ghost"
                      fontSize="48px"
                      onClick={() => {
                        clearTimeout(TIMER);
                        if (!recording) {
                          setIsRecordingCompleted(false);
                        }
                        setRecording(!recording);
                        TIMER = setTimeout(() => {
                          setRecording(false);
                          //在这里设置进度条的显示，
                          // setProgress(100);
                        }, 1000 * 3.5);
                      }}
                      // onMouseDown={(e) => {
                      //   console.log("Mouse down");
                      //   setRecording(true);
                      //   setTimeout(() => {
                      //       setRecording(false);
                      //   }, 3000);
                      // }}
                      // onMouseUp={(e) => {
                      //     // setRecording(false);
                      //     setTimeout(() => {
                      //       setRecording(false);
                      //     }, 300);
                      //   }
                      // }
                      icon={
                        <FontAwesomeIcon
                          icon={recording ? faStop : faRecordVinyl}
                          color={
                            recording
                              ? getColor(theme, "red.500")
                              : getColor(theme, "blue.500")
                          }
                        />
                      }
                    />

                    {audioData && (
                      <Box w="100%">
                        <audio
                          id="audio"
                          controls
                          src={audioData.blobURL}
                          style={{ width: "100%" }}
                        />
                      </Box>
                    )}
                  </HStack>
                </VStack>
              </ModalBody>
            </StyledModalContent>
          </Modal>
        </Flex>
        <EnhancedHandle
          type="source"
          position={Position.Right}
          isConnectable={isConnectable}
          id="audio-output"
        />
      </>
    );
  }
);

export default withPlayBtn(AudioNode);
