import React from "react";
import styled from "@emotion/styled";
import { HStack, VStack, Box, Icon, useMediaQuery} from "@chakra-ui/react";
import {
  FaMicrophone,
  FaRetweet,
  FaDice,
  FaClock,
  FaDrum,
  FaPlay,
} from "react-icons/fa";
import { MdAccountTree } from "react-icons/md";
import { IconType } from "react-icons";
import {GiAlarmClock} from "react-icons/gi"
import { NodeTypes } from "@/lib/types";
import useStore from "@/store/useStore";

const Container = styled(VStack)`
  width: 100%;
  background-color: #fed89f;
  backdrop-filter: blur(10px);
  overflow-y: auto;
`;

const Dot = styled.div<{ type: "source" | "target" }>`
  background-color: #96b38a;
  height: 14px;
  width: 14px;
  border-radius: 50%;
  border: solid 1px #fcfcfc;
  position: absolute;
  top: calc(50% - 7px); 
  margin-top: 0px !important;

  ${({ type }) => {
    return `left: ${type == "source" ? "-7px" : "calc(100% - 7px)"};`; // this is the dot in the Block menu
  }}
`;

const nodes: NodeTemplateProps[] = [
  {
    name: "Record",
    icon: FaMicrophone,
    nodeType: NodeTypes.AudioNode,
  },
  {
    name: "Drum",
    icon: FaDrum,
    nodeType: NodeTypes.DrumNode,
  },
  {
    name: "Play",
    icon: FaPlay,
    nodeType: NodeTypes.PlayNode,
  },
  {
    name: "Loop",
    icon: FaRetweet,
    nodeType: NodeTypes.LoopNode,
  },
  {
    name: "Interval",
    icon: FaClock,
    nodeType: NodeTypes.IntervalNode,
  },
  {
    name: "Alarm",
    icon: GiAlarmClock,
    nodeType: NodeTypes.TimeNode,
  },
  {
    name: "Random",
    icon: FaDice,
    nodeType: NodeTypes.RandomNode,
  },
  {
    name: "Switch",
    icon: MdAccountTree,
    nodeType: NodeTypes.SwitchNode,
  },
];

interface NodeTemplateProps {
  name: string;
  icon: IconType;
  nodeType: NodeTypes;
  id?: string;
  draggable?: boolean;
  hasLeftHandle?: boolean;
  hasRightHandle?: boolean;
  onDragStart?: Function;
  [key: string]: any;
}

const NodeTemplate = ({
  name,
  icon,
  nodeType,
  id,
  draggable = true,
  hasLeftHandle = true,
  hasRightHandle = true,
  onDragStart,
  ...rest
}: NodeTemplateProps) => {
  const _onDragStart = (event, nodeType, id) => {
    onDragStart?.();
    event.dataTransfer.setData("application/reactflow", nodeType);
    event.dataTransfer.setData("application/id", id);
    event.dataTransfer.effectAllowed = "move";
  };

  return (
    <VStack
      minWidth={"168px"}
      h="110px"
      justify={"center"}
      bg="#9CA0D1"
      borderRadius={"5px"} 
      p={1}
      border={"solid 1px #3E3E3E"}
      position={"relative"}
      onDragStart={(e) => _onDragStart(e, nodeType, id)}
      cursor={"pointer"}
      draggable={draggable}
      className="nodeTemplate"
      {...rest}
    >
      {hasLeftHandle && <Dot type="source" />}
      {hasRightHandle && <Dot type="target" />}
      <Box color="#fcfcfc" fontSize={"1.25em"}>
        {name}
      </Box>
      <Box>
        <Icon as={icon} h={12} w={12} color="#f8f8f8" />
      </Box>
    </VStack>
  );
};

export interface DockProps {
  data?: NodeTemplateProps[];
}

export default ({ data = [] }) => {
  const selectedPhase = useStore((state) => state.selectedPhase);
  const [isMobile] = useMediaQuery("(max-width: 768px)");

  let selectNodes = (nodes: NodeTemplateProps[]) => {
    switch (selectedPhase) {
      case 1:
        return nodes.slice(0, 3)
      case 2:
        return nodes.slice(0, 5)
      case 3:
      default:
        return nodes
    }
  }


  return isMobile ? null : (
    <Container p={3}>
      <HStack spacing={8} w="100%" style={{ height: "100%" }}>
        {[
          ...selectNodes(nodes).map((node) => (
            <NodeTemplate {...node} key={node.id ?? node.nodeType} />
          )),
        ].filter(node => !!node)}
      </HStack>
    </Container>
  );
};
