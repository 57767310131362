import React, { memo, useState, useEffect } from "react";
import { Handle, Position, NodeProps, Node } from "react-flow-renderer";
import withPlayBtn from "@/HOCs/withPlayBtn";
import {
  Flex,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
  Button,
} from "@chakra-ui/react";
import styled from "@emotion/styled";
import { darken } from "polished";
import { Howl } from "howler";
import posthog from 'posthog-js'

import EnhancedHandle from "@/components/enhancedHandle"

export interface PadData {
  letter: string;
  keycode: number;
  id: string;
  url: string;
}

const DATA: PadData[] = [
  {
    letter: "Q",
    keycode: 81,
    id: "Open-HH",
    url: "/sound/Dsc_Oh.mp3",
  },
  {
    letter: "W",
    keycode: 87,
    id: "Closed-HH",
    url: "/sound/Cev_H2.mp3",
  },
  {
    letter: "E",
    keycode: 69,
    id: "Kick-and-Hat",
    url: "/sound/Kick_n_Hat.mp3",
  },
  {
    letter: "A",
    keycode: 65,
    id: "Punchy-Kick",
    url: "/sound/punchy_kick_1.mp3",
  },
  {
    letter: "S",
    keycode: 83,
    id: "Kick",
    url: "/sound/RP4_KICK_1.mp3",
  },
  {
    letter: "D",
    keycode: 68,
    id: "Snare",
    url: "/sound/Brk_Snr.mp3",
  },
  {
    letter: "Z",
    keycode: 90,
    id: "Side-Stick",
    url: "/sound/side_stick_1.mp3",
  },
  {
    letter: "X",
    keycode: 88,
    id: "Clap",
    url: "/sound/Heater-6.mp3",
  },
  {
    letter: "C",
    keycode: 67,
    id: "Shaker",
    url: "/sound/Give_us_a_light.mp3",
  },
];

const onStyle = {
  transform: "scale(0.95)",
  boxShadow: "1px 1px 4px 4px cyan, -1px -1px 4px 4px cyan",
};
const offStyle = { transform: "scale(1)", boxShadow: "none" };

export interface PadState {
  playing: boolean;
}

export interface PadProps {
  power: boolean;
  pad: PadData;
  volume: number;
  updateDisplay: (padID: string) => void;
}

class Pad extends React.Component<PadProps, PadState> {
  constructor(props) {
    super(props);
    this.state = {
      playing: false,
    };
    this.handleKeyPress = this.handleKeyPress.bind(this);
    this.onPlay = this.onPlay.bind(this);
  }

  componentDidMount() {
    document.addEventListener("keydown", this.handleKeyPress);
  }

  componentWillUnmount() {
    document.removeEventListener("keydown", this.handleKeyPress);
  }

  handleKeyPress(e) {
    if (e.keyCode === this.props.pad.keycode) {
      this.onPlay();
    }
  }

  onPlay() {
    if (this.props.power) {
      const sound = document.getElementById(
        this.props.pad.letter
      ) as HTMLAudioElement;
      sound.currentTime = 0;
      sound.volume = this.props.volume;
      sound.play();
      this.props.updateDisplay(this.props.pad.id);
      this.setState({ playing: true });
      setTimeout(() => {
        this.setState({ playing: false });
      }, 100);
    }
  }

  render() {
    const style = !this.props.power
      ? { background: "#476b68" }
      : this.state.playing
      ? onStyle
      : offStyle;
    return (
      <div style={style} className="outer-drum-pad">
        <div className="drum-pad" id={this.props.pad.id} onClick={this.onPlay}>
          <audio
            id={this.props.pad.letter}
            src={this.props.pad.url}
            className="clip"
          ></audio>
          {this.props.pad.letter}
        </div>
      </div>
    );
  }
}

export interface DrumMachineProps {
  data: PadData[];
  onChangeSound: (padID: string) => void;
}

export interface DrumMachineState {
  power: boolean;
  currentSound: string;
  volumeInput: number;
  volume: number;
}
class DrumMachine extends React.Component<DrumMachineProps, DrumMachineState> {
  constructor(props) {
    super(props);
    this.state = {
      currentSound: "",
      power: true,
      volumeInput: 50,
      volume: 0.5,
    };
    this.updateDisplay = this.updateDisplay.bind(this);
    this.togglePower = this.togglePower.bind(this);
    this.changeVolume = this.changeVolume.bind(this);
  }

  updateDisplay(id) {
    this.setState({ currentSound: id });
    this.props.onChangeSound(id);
  }

  togglePower() {
    const message = !this.state.power && "Welcome";
    this.setState({ power: !this.state.power, currentSound: message });
    setTimeout(() => {
      this.setState({ currentSound: "" });
    }, 1500);
  }

  changeVolume(e) {
    const volume = e.target.value / 100;
    const message = "Volume: " + e.target.value;
    this.setState({
      volume: volume,
      volumeInput: e.target.value,
      currentSound: message,
    });
  }

  render() {
    const pads = this.props.data.map((pad, i) => {
      return (
        <Pad
          key={i}
          pad={pad}
          updateDisplay={this.updateDisplay}
          power={this.state.power}
          volume={this.state.volume}
        />
      );
    });

    return (
      <div className="machine">
        <div className="pads">{pads}</div>
        <div
          style={{
            color: "#fcfcfc",
            width: 128,
            textAlign: "center",
            display: "flex",
            alignItems: "center",
            margin: "0 8px",
          }}
        >
          <span>Selected Effect: {this.state.currentSound}</span>
        </div>
      </div>
    );
  }
}

const StyledModelBody = styled(ModalBody)`
  .machine {
    width: 100%;
    display: flex;
    background: #111;
    margin-bottom: 24px;
    padding: 20px;
    border-radius: 5px;
    box-shadow: 5px 5px 8px 3px #333;
  }

  .pads {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    justify-content: space-between;
  }

  .outer-drum-pad {
    min-width: 120px;
    height: 120;
    background: #1ec8ce;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 10px;
  }

  .drum-pad {
    width: 97%;
    height: 97%;
    background: radial-gradient(#333, #070707);
    border-radius: 50%;
    text-align: center;
    vertical-align: middle;
    line-height: 120px;
    font-size: 24px;
    color: rgba(255, 255, 255, 0);
    cursor: pointer;
    &:hover {
      color: rgba(255, 255, 255, 1);
    }
  }

  .side-panel {
    height: 100%;
    width: 40%;
    padding: 20px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    color: white;
    text-align: center;
    font-size: 20px;
  }

  .label {
    color: white;
    font-style: italic;
    text-align: center;
    font-size: 24px;
  }

  .display {
    width: 200px;
    height: 20px;
    margin: 20px auto;
    padding: 5px;
    font-size: 20px;
    background: #1ec8ce;
    border: 2px solid #333;
    border-radius: 3px;
    color: #111;
  }

  .speakers {
    width: 100%;
    padding: 20px;
    hr {
      background: #000;
      width: 90%;
      height: 9px;
      margin: 15px 0;
      border: none;
    }
  }

  button {
    background: green;
    width: 40px;
    height: 20px;
    margin: 10px 0 5px;
    cursor: pointer;
  }

  input {
    margin: 10px;
  }
`;

const ToggleBtn = styled.div`
  padding: 12px;
  border: 1px solid #fff;
  border-radius: 5px;

  &:hover {
    z-index: 1;
    cursor: pointer;
    background-color: ${darken(0.3, "#fff")};
  }
`;

export interface DrumNodeData {
  onChange: (data: { blobURL: string; nodeID: string, selectedDrumEffect: PadData }) => void;
  onPlay: (node: Partial<Node>) => void;
  selectedDrumEffect: PadData
}

const DrumNode = memo(({ data, isConnectable, id }: NodeProps<DrumNodeData>) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [selectedDrumEffect, setSelectedDrumEffect] = useState<PadData>(data.selectedDrumEffect);

  const onChangeSound = (padID: string) => {
    posthog.capture('onSelectDrumEffect', { payload: padID });
    setSelectedDrumEffect(DATA.find((pad) => pad.id === padID));
  };

  useEffect(() => {
    // preload audio
    if (selectedDrumEffect?.url) {
      new Howl({
        src: selectedDrumEffect.url, // TODO: remove
        format: ["webm", "m4a", "mp3"],
        //html5:true,
      });
      data.onChange({ nodeID: id, blobURL: selectedDrumEffect.url, selectedDrumEffect });
    }
  }, [selectedDrumEffect])


  return (
    <div>
      <EnhancedHandle
        type="target"
        position={Position.Left}
        isConnectable={isConnectable}
        id={`drum-input-${id}`}
      />

      <Flex 
        h="100%"
        direction={"column"}
        align="center"
        justify={"center"}
        p={3}
      >
        <ToggleBtn onClick={onOpen}>Choose Drum Effect</ToggleBtn>
        {selectedDrumEffect && (
          <p style={{ marginTop: 6 }}>Selected: {selectedDrumEffect.id}</p>
        )}

        <Modal isOpen={isOpen} onClose={onClose} size={"2xl"}>
          <ModalOverlay />
          <ModalContent>
            <ModalHeader>Drum Machine</ModalHeader>
            <ModalCloseButton />
            <StyledModelBody>
              <DrumMachine data={DATA} onChangeSound={onChangeSound} />
            </StyledModelBody>
          </ModalContent>
        </Modal>
      </Flex>
      <EnhancedHandle
        type="source"
        position={Position.Right}
        isConnectable={isConnectable}
        id={`drum-output-${id}`}
      />
    </div>
  );
});

export default withPlayBtn(DrumNode);
