import React, { memo, useEffect, useMemo, useRef } from 'react';
import { Handle, Position, NodeProps } from 'react-flow-renderer';
import { Flex, Icon, Input, Text, Slider, SliderTrack, SliderFilledTrack, SliderThumb, SliderMark, Tooltip, Spacer, Box, color, background, } from '@chakra-ui/react'
import { AiOutlinePlusSquare, AiOutlineMinusSquare } from "react-icons/ai";
import styled from "@emotion/styled";

import withPlayBtn from "@/HOCs/withPlayBtn";
import EnhancedHandle from "@/components/enhancedHandle"
import posthog from 'posthog-js';
import { stringify } from 'flatted';
import useStore from "@/store/useStore";

export interface RandomNodeData {
  onChange: (data: any) => void;
  probDist?: number;
}

const StyledIcon = styled(Icon)`
  width: 20%;
  height: auto;
  transition: color 0.3s;
  cursor: pointer;
  &:hover {
    color: var(--chakra-colors-blackAlpha-700);
  }
`;
const RandomNode = memo(
  ({ data, isConnectable, id }: NodeProps<RandomNodeData>) => {
  const setProbDistMap = useStore((state) => state.setProbDistMap);
  const [sliderValue, setSliderValue] = React.useState(data.probDist ?? 50);
  const [showTooltip, setShowTooltip] = React.useState(false);

  const onChangeProbDist = (probDist: number) => {
    probDist = Math.min(Math.max(probDist, 0), 100)
    data.onChange({ probDist, nodeID: id });
    console.log(`proDist is : ${probDist}`)

    posthog.capture('onChangeProbDist', {
      payload: stringify({ probDist, nodeID: id }),
    })
  };

  useEffect(() => {
    setProbDistMap({ id, probDist:  data.probDist});
  }, [data.probDist]);

  return (
    <>
      <EnhancedHandle
        type="target"
        position={Position.Left}
        isConnectable={isConnectable}
        id='random-input'

      />
      <Flex h={'100%'} align='center' justify={'center'} paddingLeft={7}>
        <Text mb={1} >
            {`Random Branching`}
        </Text>


        <Slider orientation='vertical' height={85} value = {sliderValue}
        onChange = {(v) => setSliderValue(v)}
        onChangeEnd = {(v) => onChangeProbDist(v)}
        onMouseEnter={() => setShowTooltip(true)}
        onMouseLeave={() => setShowTooltip(false)}
        >
          <SliderTrack bg={'teal'}>
            <SliderFilledTrack bg='pink'/>
          </SliderTrack>
        <Tooltip
          fontSize={'xx-small'}
          hasArrow
          bg='teal.500'
          color='white'
          placement='top'
          isOpen={showTooltip}
          label={`${sliderValue}% / ${ 100 - sliderValue}%`}
        >
           <SliderThumb boxSize={6}/>
        </Tooltip>
        </Slider>

        <Flex direction={'column'} justifyContent='space-between' fontSize={12} textAlign={'right'} boxSize='45' height='90'>
          <Text color='pink'>{sliderValue ?? 50}% </Text>
          <Text color='teal'>{100 - (sliderValue ?? 50)}%</Text>
        </Flex>

      </Flex>

      <EnhancedHandle
        annotation={`OUT_1`}
        annotationStyle={{ right: -70, bottom: -6 }}
        type="source"
        position={Position.Right}
        isConnectable={isConnectable}
        style={{ top: '20%' }}
        id={`${id}_0`}
      />
      <EnhancedHandle
        annotation={`OUT_2`}
        annotationStyle={{ right: -70, bottom: -6 }}
        type="source"
        position={Position.Right}
        isConnectable={isConnectable}
        style={{ top: '80%' }}
        id={`${id}_1`}
      />
    </>
  );
});

export default withPlayBtn(RandomNode)