import { FlowElement } from "react-flow-renderer";

/*
    localFlow is just like other flows
    The only difference is that localFlow is stored in localStorage
*/

const localFlowId = "draft"
const localFlowName = "New Project";

export const updateLocalFlow = async (elements: FlowElement[]) => {
//   if (!elements.length) return;
  const uploadedElements = await Promise.all(
    (elements ?? []).map(async (element) => {
      if (element.data?.blobURL && element.data?.blobURL.includes("blob")) {
        let blob = await fetch(element.data.blobURL).then((r) => r.blob());
        const dataUrl = await blobToDataURL(blob);
        element.data.dataUrl = dataUrl;
      }
      return element;
    })
  );
  const newLocalFlow = {
    elements: uploadedElements,
    name: localFlowName,
  };
  const localFlowString = JSON.stringify(newLocalFlow);
  console.log("before updateLocalFlow: ", localFlowString);
  window.localStorage.setItem(localFlowId, localFlowString);
};

export const getLocalFlow = () => {
  const localFlowString = window.localStorage.getItem(localFlowId);
  if (!localFlowString) {
    // no local stored flow
    return { elements: [] as FlowElement[], name: localFlowName };
  }
  const localFlow = JSON.parse(localFlowString);
  const localElements = localFlow.elements;
  localElements.map((element) => {
    if (element.data?.dataUrl) {
      element.data.blob = dataURLToBlob(element.data.dataUrl);
    }
    return element;
  });
  console.log("localFlowString: ", localFlowString);
  return localFlow;
};

export const getLocalFlowName = () => {
  return localFlowName;
};

function blobToDataURL(blob: Blob): Promise<string> {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = () => {
      const dataURL = reader.result as string;
      resolve(dataURL);
    };
    reader.onerror = reject;
    reader.readAsDataURL(blob);
  });
}

function dataURLToBlob(dataURL: string): Blob {
  const byteString = (() => {
    if (typeof window !== "undefined") {
      // For browser environment
      return window.atob(dataURL.split(",")[1]);
    } else {
      // For Node.js environment
      return Buffer.from(dataURL.split(",")[1], "base64").toString("binary");
    }
  })();

  const arrayBuffer = new ArrayBuffer(byteString.length);
  const uint8Array = new Uint8Array(arrayBuffer);
  for (let i = 0; i < byteString.length; i++) {
    uint8Array[i] = byteString.charCodeAt(i);
  }

  const mimeType = dataURL.split(",")[0].split(":")[1].split(";")[0];
  return new Blob([arrayBuffer], { type: mimeType });
}
