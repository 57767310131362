import React, { useCallback, useEffect } from "react";
import styled from "styled-components";
import { FaLevelUpAlt, FaLevelDownAlt } from "react-icons/fa";
import { Tooltip, message } from "antd";
import { posthog } from "posthog-js";
import { stringify } from 'flatted'

import useStore from "@/store/useStore";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 0.5rem;
  background-color: rgba(255, 255, 255, 0.35);
  backdrop-filter: blur(4px);
  position: fixed;
  bottom: 76px;
  right: 24px;
  height: 148px;
  width: 66px;
  z-index: 9999;
  border-radius: 5px;

  .current-level {
    color: rgba(255, 255, 255, 0.9);
    margin: 12px 0;
    text-align: center;
    user-select:none;
  }

  svg {
    cursor: pointer;
    transition: 0.3s ease-in-out;
    color: #f8f8f8;
    width: 100%;
    flex: 1;
  }

  svg:hover {
    color: #343a40;
  }

  svg[aria-disabled="true"] {
    color: #a9a9a9;
    cursor: not-allowed;
  }
`;

export interface PhaseControlProps {}

const PhaseControl: React.FC<PhaseControlProps> = ({}) => {
  const selectedPhase = Math.min(3, useStore((state) => state.selectedPhase));
  //console.log('selectedPhase is ', selectedPhase)
  const setPhase = useCallback(
    useStore((state) => state.setPhase),
    []
  );

  useEffect(() => {
    message.info(`Level ${selectedPhase} selected`);
  }, [selectedPhase]);

  return (
    <Container>
      <Tooltip placement="left" title="Show more advanced blocks">
        <FaLevelUpAlt
          aria-disabled={selectedPhase >= 3}
          onClick={() => {
            setPhase(Math.min(3, selectedPhase + 1));
            posthog.capture("phase-up", {
              payload: stringify({ currentPhase: selectedPhase, newPhase: selectedPhase + 1}),
            })
          }}
        />
      </Tooltip>
      <div className="current-level">Level<br />{selectedPhase}</div>
      <Tooltip placement="left" title="Show basic blocks">
        <FaLevelDownAlt
          aria-disabled={selectedPhase <= 1}
          onClick={() => {
            setPhase(Math.max(1, selectedPhase - 1));
            posthog.capture("phase-down", {
              payload: stringify({ currentPhase: selectedPhase, newPhase: selectedPhase - 1}),
            })
          }}
        />
      </Tooltip>
    </Container>
  );
};

export default PhaseControl;
