import React, { memo, useEffect, useMemo } from "react";
import { Handle, Position, NodeProps } from "react-flow-renderer";
import { Flex, Input, Text, Slider, SliderTrack, SliderFilledTrack, SliderThumb, SliderMark, Tooltip, color, } from "@chakra-ui/react";
import styled from "@emotion/styled";
import { Button, message } from "antd";
import { posthog } from "posthog-js";
import { stringify } from 'flatted'

import withPlayBtn from "@/HOCs/withPlayBtn";
import EnhancedHandle from "@/components/enhancedHandle";
import useStore from "@/store/useStore";
import { values } from "rambda";

const StyledInput = styled(Input)`
  padding: 8px;
  width: 48px;
  height: 32px;
  margin: 4px;
  display: inline-block;
  ::placeholder{
    color: Ivory;
  }
`;

export interface IntervalNodeData {
  interval: number;
  loop: number;
  showThenHandle?: boolean;
  onChange: (data: any) => void;
}

const IntervalNode = memo(
  ({ data, isConnectable, id }: NodeProps<IntervalNodeData>) => {
    const loopMap = useStore(state => state.loopMap)

    const intervalMap = useStore.getState().intervalMap;
    const setIntervalMap = useStore((state) => state.setIntervalMap);

    const loop = useMemo(() => {
      const runningLoop = loopMap.get(id)
      if (runningLoop !== undefined) {
        return runningLoop
      }
      return data.loop
    }, [data.loop, loopMap])

    const [intervalInput, setIntervalInput] = React.useState(data.interval ?? 0);

    // Slider
    //const [sliderValue, setSliderValue] = React.useState(0)
    const [showTooltip, setShowTooltip] = React.useState(false)
    const onChangeInterval = ( (interval: number) => {
      //interval = (interval !== 0)? interval : 0.1;
      posthog.capture("onChangeInterval [INTERVAL]", {payload: stringify({interval, nodeID: id })});
      setIntervalInput(interval)
      data.onChange({interval, nodeID: id });
    })
    // useStore to allow dynamic update
    useEffect(() => {
      setIntervalMap({ id, interval:  data.interval});
      console.log(`useEffect triggered: ${data.interval}`)
      posthog.capture('onChangeInterval', {
        payload: stringify({ nodeID: id, probDist: data.interval }),
      })
    }, [data.interval]);

    return (
      <>
        <EnhancedHandle
          type="target"
          position={Position.Left}
          isConnectable={isConnectable}
          id={`${id}-input`}
        />
        <Flex
          h="100%"
          justify={"center"}
          align="center"
          direction={"row"}
          p={3}
        >

          <Text mb={1}>
            Repeat every<br />

            <StyledInput
              width={"64px"}
              type= {"number"}
              className="nodrag"
              placeholder="1"
              value = {intervalInput == 0? data.interval?? '' : intervalInput}
              onKeyPress = {(e) => {
                if (e.key == 'Enter') onChangeInterval(intervalInput) }}
              onBlur = {(e) => onChangeInterval(intervalInput) }
              onChange = {(e) => {
                setIntervalInput(parseFloat(e.target.value));
                //setIntervalInput move Slider thus lost focus of input, used short delay of focus() to solve the issue.
                e.target.focus()
                setTimeout(()=> e.target.focus(), 100)
              } }
            />
            <Slider min = {0.1} max={2} value = {intervalInput == 0? 1 : intervalInput} step={0.01} display={'inline-flex'} width='36'
              onChange = {(v) => onChangeInterval(v)}
              onMouseEnter={() => setShowTooltip(true)}
              onMouseLeave={() => setShowTooltip(false)}
              >
              <SliderTrack bg='orange.100'>
                <SliderFilledTrack bg='orange.300'/>
              </SliderTrack>
              <Tooltip
                fontSize={'xx-small'}
                hasArrow
                bg='teal.500'
                color='white'
                placement='top'
                isOpen={showTooltip}
                label={`${data.interval || 1} times`}
              >
                <SliderThumb  boxSize={6}/>
              </Tooltip>
            </Slider>

            seconds for{"  "}
            <br />

            <StyledInput
              width={"64px"}
              type="nunber"
              className="nodrag"
              placeholder={"∞"}
              value={loop || ''}
              onChange={(e) => {
                if (!Number.isInteger(parseFloat(e.target.value))) {
                  if (e.target.value) {
                    message.error("Loop time can only be an integer");
                    return;
                  }
                }
                posthog.capture("onChangeLoop [INERVAL]", { payload: stringify({ nodeID: id, loop: e.target.value }) })
                data.onChange({ loop: parseInt(e.target.value), nodeID: id });
              }}
            />{" "}
            times
          </Text>

        </Flex>

        <EnhancedHandle
          annotation="do"
          type="source"
          position={Position.Right}
          isConnectable={isConnectable}
          style={{ top: "20%" }}
          onConnect={_=> data.onChange({ showThenHandle: true, nodeID: id })}
          annotationStyle={{ right: -4, }}
          id={`${id}-output`}
        />

        <EnhancedHandle
        annotation="then"
        type="source"
        position={Position.Right}
        isConnectable={isConnectable}
        style={{ top: "80%", visibility: data.showThenHandle ? 'visible' : 'hidden' }}
        id={`${id}-exit`}
        />
      </>
    );
  }
);

export default withPlayBtn(IntervalNode);
