export default {
    animations: {
      rainbow: `
        background: linear-gradient(
          to right,
          #6666ff,
          #0099ff,
          #00ff00,
          #ff3399,
          #6666ff
        );
  
        -webkit-background-clip: text;
        background-clip: text;
        color: transparent;
        background-size: 400% 100%;
  
        @keyframes rainbow {
          0%,
          100% {
            background-position: 0 0;
          }
  
          50% {
            background-position: 100% 0;
          }
        }
      `,
      shake: `
        -webkit-background-clip: text;
        background-clip: text;
        -webkit-text-fill-color: transparent;
        -moz-background-clip: text;
        -moz-text-fill-color: transparent;
        -ms-background-clip: text;
        -ms-text-fill-color: transparent;
        text-fill-color: transparent;
        display: inline-block;
  
        @keyframes shake {
          0% {
            transform: skewX(-30deg);
          }
          1% {
            transform: skewX(30deg);
          }
          2% {
            transform: skewX(-30deg);
          }
          3% {
            transform: skewX(30deg);
          }
          4% {
            transform: skewX(0deg);
          }
          100% {
            transform: skewX(0deg);
          }
        }
      `,
    },
  };
  