export enum NodeTypes {
    AudioNode = 'audioNode',
    LoopNode = 'loopNode',
    RandomNode = 'randomNode',
    SwitchNode = 'switchNode',
    IntervalNode = 'intervalNode',
    DrumNode = 'drumNode',
    PlayNode = 'playNode',
    moduleWrapper = 'moduleWrapper',
    moduleNode = 'moduleNode',
    TimeNode = 'timeNode'
}

export interface CancellablePromise {
    cancel(): void
    promise: Promise<any>
}


export enum FlowTreeEvents {
  AddActiveNode = 'AddActiveNode',
  AddActiveEdge = 'AddActiveEdge',
  RemoveActiveNode = 'RemoveActiveNode',
  RemoveActiveEdge = 'RemoveActiveEdge',
  UpdateAudioNodeProgress = 'UpdateAudioNodeProgress',
  UpdateLoopCount = 'UpdateLoopCount',
  ProcessEnd = 'processEnd',
}

export type AudioNodeProgress = { id: string, progress: number }
