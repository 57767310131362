import {
	AStarFinder,
	Util,
	DiagonalMovement
	// JumpPointFinder
} from 'pathfinding'
import type { Grid } from 'pathfinding'
import type { XYPosition } from 'react-flow-renderer'

// https://www.npmjs.com/package/pathfinding#advanced-usage
declare module 'pathfinding' {
	interface FinderOptions extends Heuristic {
		diagonalMovement?: DiagonalMovement
		weight?: number
		allowDiagonal?: boolean
		dontCrossCorners?: boolean
	}
}

function smoothenPath(grid, path) {
    var len = path.length,
        x0 = path[0][0],        // path start x
        y0 = path[0][1],        // path start y
        x1 = path[len - 1][0],  // path end x
        y1 = path[len - 1][1],  // path end y
        sx, sy,                 // current start coordinate
        ex, ey,                 // current end coordinate
        newPath,
        i, j, coord, line, testCoord, blocked, lastValidCoord;

    sx = x0;
    sy = y0;
    newPath = [[sx, sy]];

    for (i = 2; i < len; ++i) {
        coord = path[i];
        ex = coord[0];
        ey = coord[1];
        line = Util.interpolate(sx, sy, ex, ey);

        blocked = false;
        for (j = 1; j < line.length; ++j) {
            testCoord = line[j];

            if (!grid.isWalkableAt(testCoord[0], testCoord[1])) {
                blocked = true;
                break;
            }
        }
        if (blocked) {
            lastValidCoord = path[i - 1];
            newPath.push(lastValidCoord);
            sx = lastValidCoord[0];
            sy = lastValidCoord[1];
        }
    }
    
	newPath.push([x1, y1]);

    return newPath;
}

const withDiagonalMovement = {
	allowDiagonal: true,
	dontCrossCorners: true,
	diagonalMovement: DiagonalMovement.Always
}

const withStraightMovement = {
	allowDiagonal: false
}

export const generatePath = (
	grid: Grid,
	start: XYPosition,
	end: XYPosition,
	lessCorners: boolean
) => {
	const finderOptions = lessCorners
		? withStraightMovement
		: withDiagonalMovement

	const finder = new AStarFinder(finderOptions)

	let fullPath: number[][] = []
	let smoothedPath: number[][] = []

	try {
		const distance = Math.sqrt((end.x - start.x) ** 2 + (end.y - start.y) ** 2)
		if (distance < 24) {
			fullPath =  [[start.x, start.y], [end.x - 10, end.y]]
		} else {
			fullPath = finder.findPath(start.x, start.y, end.x, end.y, grid)
		}
		smoothedPath = smoothenPath(grid, fullPath)
	} catch {
		// No path was found. This can happen if the end point is "surrounded"
		// by other nodes, or if the starting and ending nodes are on top of
		// each other.
	}

	return { fullPath, smoothedPath }
}
