import { Edge, Node, FlowElement, isNode, isEdge } from "react-flow-renderer";
import { reject as RRject, flatten } from 'rambda';

export class FlowTree {
  private treeLikeData;

  constructor(treeLikeData: { [key: string]: FlowTreeNode }) {
    this.treeLikeData = treeLikeData;
  }

  public getTreeLikeData() {
    return this.treeLikeData
  }

  public getRoots(): FlowTreeNode[] {
    return Object.values(this.treeLikeData);
  }

  public findNode(id: string, graph: { [key: string]: FlowTreeNode } = this.treeLikeData): FlowTree {
    let target: FlowTreeNode = graph[id];
    if (target) {
      return new FlowTree({ [target.id]: target })
    } else {
      for (const node of Object.values(graph)) {
        const tree = Array.from(node.children).reduce((cur, next) => {
          cur[next.id] = next
          return cur
        }, {})
        const res = this.findNode(id, tree)
        if (res) {
          return res
        }
      }
    }
  }
}

export function isCyclic (obj: { [key: string]: FlowTreeNode }, visited = new Set()) {
  const cycle = findCycle(obj)
  return !!cycle
}

export function findCycle(graph: { [key: string]: FlowTreeNode }): string[] | undefined {
  let visited = new Set();
  let result;

  // dfs set the result to a cycle when the given node was already on the current path.
  //    If not on the path, and also not visited, it is marked as such. It then
  //    iterates the node's children and calls the function recursively.
  //    If any of those calls returns true, exit with true also
  function dfs(node, path) {
    if (path.has(node)) {
      result = [...path, node]; // convert to array (a Set maintains insertion order)
      result.splice(0, result.indexOf(node)); // remove part that precedes the cycle
      return true;
    }

    if (visited.has(node)) return;

    path.add(node);
    visited.add(node);

    if (
      (graph[node] ? Array.from(graph[node].children).map((c) => c.id) : []).some((child) =>
        dfs(child, path)
      )
    ) 
      return path;

    // Backtrack
    path.delete(node);
    // No cycle found here: return undefined
  }

  // Perform a DFS traversal for each node (except nodes that get
  //   visited in the process)
  for (let node in graph) {
    if (!visited.has(node) && dfs(node, new Set())) return result;
  }
}

export interface FlowTreeNode extends Node {
  id: string;
  children: Set<FlowTreeNode>;
  parent?: Set<string>;
  sourceHandle?: string[];
}

export const getNodesWithConnection = (nodes: Node[], edges: Edge[]): Node[] => {
  const nodeIds = flatten(edges.map((edge) => [edge.source, edge.target])).filter(id => !!id);

  return nodes.filter((node) => nodeIds.includes(node.id));
};

export const getIsolatedNodes = (nodes: Node[], edges: Edge[]): Node[] => {
  const nodesWithConnectionIds = getNodesWithConnection(nodes, edges).map(n => n.id);
  return nodes.filter((node) => !nodesWithConnectionIds.includes(node.id))
};

export function elements2Tree(elements: FlowElement[], roots?: Node[]): FlowTree {
  const nodes = elements.filter((e) => isNode(e)) as Node[];
  const edges = elements.filter((e) => isEdge(e)) as Edge[];

  const tree: { [key: string]: any } = {};
  const children: { [key: string]: FlowTreeNode } = {};
  const data: { [key: string]: Node } = {};

  nodes.forEach((n) => {
    data[n.id] = n;
  });

  // all children
  edges.forEach((elem) => {
    children[elem.target] = children[elem.target] ?? {
      id: elem.target,
      parent: new Set<string>(),
      children: new Set<FlowTreeNode>(),
      sourceHandle: [elem.sourceHandle ?? elem.source],
      ...data[elem.target]
    };
    children[elem.target]['sourceHandle'].push(elem.sourceHandle)
    children[elem.target].parent.add(elem.source);
  });

  // parents
  edges.forEach((elem) => {
    if (!children[elem.source]) {
      tree[elem.source] = tree[elem.source] ?? {
        children: new Set(),
        ...data[elem.source],
      };
      tree[elem.source].children.add(children[elem.target]);
    } else {
      children[elem.source].children.add(children[elem.target]);
      // Array.from(children[elem.source].parent as Set<string>).forEach(p => {
      //     tree[p] = tree[p] ?? { ...data[p], children: new Set() };
      //     tree[p].children.add(children[elem.source]);
      // });
    }
  });

  // handle isolated node
  getIsolatedNodes(nodes, edges).forEach((n) => {
    tree[n.id] = tree[n.id] ?? {
      id: n.id,
      parent: new Set<string>(),
      children: new Set<FlowTreeNode>(),
      ...data[n.id]
    }
  });

  // resolve cycle
  let cycle = findCycle(children)
  let visited = new Set<string>(cycle)
  while (cycle && cycle.length > 0) {
    tree[cycle[0]] = tree[cycle[0]] ?? children[cycle[0]]
    cycle = findCycle(RRject((value: FlowTreeNode) => visited.has(value.id), children))
    if (cycle) {
      visited = new Set([...cycle, ...Array.from(visited)])
    }
  }

  return new FlowTree(tree);
}
