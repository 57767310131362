import {
  isEdge,
  Elements,
  isNode,
  Node,
  Edge,
  FlowElement,
  OnLoadParams,
} from "react-flow-renderer";
// import dagre from "dagre";
import { Howl, Howler } from "howler";
import { v4 as uuid4 } from "uuid";

import { FlowTree, FlowTreeNode } from "@/lib/tree";
import { NodeTypes } from "@/lib/types";
import useStore from "@/store/useStore";

import FlowEventTarget from "./flowEvents";

function intersection(setA: Set<any>, setB: Set<any>) {
  let _intersection = new Set();
  for (let elem of setB) {
    if (setA.has(elem)) {
      _intersection.add(elem);
    }
  }
  return _intersection;
}

function arrayIntersection(a: any[], b: any[]) {
  const setA = new Set(a);
  const setB = new Set(b);
  return intersection(setA, setB);
}

export function validateCopiedElements(elms: FlowElement[]) {
  const allNodes = elms.filter((elms) => isNode(elms)).map((elms) => elms.id);
  const expectedNodes = elms
    .filter((elms) => isEdge(elms))
    .map((elms: Edge) => [elms.source, elms.target])
    .flat();
  for (const expectedNode of expectedNodes) {
    if (!allNodes.includes(expectedNode)) {
      return false;
    }
  }
  return true;
}

export function copyToClipboard(text: string) {
  const tempInput = document.createElement("input");
  try {
    tempInput.value = text;
    document.body.appendChild(tempInput);
    tempInput.select();
    document.execCommand("copy");
  } catch (err) {
    console.log(err);
  } finally {
    document.body.removeChild(tempInput);
  }
}

export function reidentifyElements({
  elms,
  counterRef,
  reactflowInstance,
  reactFlowBounds,
  positionX,
  positionY,
  basePositionX,
  basePositionY,
  commonData,
}: {
  elms: FlowElement[];
  counterRef: React.MutableRefObject<number>;
  reactflowInstance: OnLoadParams;
  basePositionX: number;
  basePositionY: number;
  positionX?: number;
  positionY?: number;
  reactFlowBounds: DOMRect;
  commonData: object;
}) {
  const idChangeMap = {};
  return elms
    .map((elem) => {
      if (isNode(elem)) {
        counterRef.current += 1;
        const newID = `${counterRef.current}_${uuid4()}_${elem.type}`;
        idChangeMap[elem.id] = newID;

        console.log(reactFlowBounds);

        return {
          ...elem,
          id: newID,
          position: {
            x:
              positionX ??
              elem.position?.x + basePositionX / 2 - reactFlowBounds.left,
            y:
              positionY ??
              elem.position?.y + basePositionY / 2 - reactFlowBounds.top,
          },
          data: {
            ...(elem.data ?? {}),
            label: `${elem.type} node`,
            ...commonData,
          },
        };
      }
      return elem;
    })
    .map((elem) => {
      if (isEdge(elem)) {
        const { type, style = {}, arrowHeadType, data, sourceHandle } = elem;
        return {
          type,
          style: { ...style, strokeWidth: 4 },
          arrowHeadType,
          data,
          source: idChangeMap[elem.source],
          target: idChangeMap[elem.target],
          sourceHandle: sourceHandle?.replace(
            elem.source,
            idChangeMap[elem.source]
          ),
          id: `"reactflow__edge-${idChangeMap[elem.source]}-${
            idChangeMap[elem.target]
          }`,
        };
      }
      return elem;
    });
}

export const handleElementsChange = (
  event: { nodeID: string; [key: string]: any },
  els: Elements
) => {
  const nodes = els.filter((e) => isNode(e)) as Node[];
  const edges = els.filter((e) => isEdge(e)) as Edge[];

  const nodeNeedEdgeUpdate: { id?: string; [key: string]: any } = {};
  const updatedNodes = nodes.map((e) => {
    if (e.id !== event.nodeID) {
      return e;
    }
    switch (e.type) {
      case NodeTypes.LoopNode:
        console.log("event: ", event);
        console.log("e: ", e);
        return {
          ...e,
          data: {
            ...e.data,
            ...event,
            loop:
              event.value === undefined ? e.data.loop : parseInt(event.value),
          },
        };
      case NodeTypes.SwitchNode:
        nodeNeedEdgeUpdate.id = e.id;
        nodeNeedEdgeUpdate.branchNum = event.branchNum;
        return {
          ...e,
          data: {
            ...e.data,
            ...event,
          },
        };
      case NodeTypes.RandomNode:
        nodeNeedEdgeUpdate.probDist = event.probDist;
        return {
          ...e,
          data: {
            ...e.data,
            ...event,
          },
        };
      case NodeTypes.TimeNode:
      case NodeTypes.AudioNode:
      case NodeTypes.DrumNode:
      case NodeTypes.PlayNode:
      case NodeTypes.IntervalNode:
        return {
          ...e,
          data: {
            ...e.data,
            ...event,
          },
        };
      default:
        return e;
    }
  });

  const updatedEdges = edges
    .map((e) => {
      if (e.source !== nodeNeedEdgeUpdate.id) {
        return e;
      }

      if (
        nodeNeedEdgeUpdate.branchNum !== undefined &&
        ![...Array(nodeNeedEdgeUpdate.branchNum).keys()]
          .map((key) => `${nodeNeedEdgeUpdate.id}_${key}`)
          .includes(e.sourceHandle)
      ) {
        return null;
      }

      return e;
    })
    .filter((e) => !!e);
  return [...updatedNodes, ...updatedEdges];
};

export interface ProcessFlowTreeArgs {
  tree: FlowTree;
  processID: string;
  cycled?: boolean;
  beforeProcessCallback?: (
    eventTarget: FlowEventTarget,
    processID: string
  ) => void;
  shouldUpdateActiveNode?: boolean;
}

export const processFlowTree = ({
  tree,
  processID,
  cycled,
  beforeProcessCallback,
  shouldUpdateActiveNode = true,
}: ProcessFlowTreeArgs) => {
  let eventEmitter = new FlowEventTarget();

  beforeProcessCallback?.(eventEmitter, processID);

  const roots = tree.getRoots();

  Promise.all(
    roots.map((root) => {
      return processNode({
        node: root,
        eventEmitter: shouldUpdateActiveNode ? eventEmitter : undefined,
        processID,
      });
    })
  )
    .then(() => {
      if (!cycled) {
        eventEmitter.onProcessEnd();
      }
    })
    .catch((err) => {
      console.log(err);
    });

  return eventEmitter;
};

interface ProcessNodeArgs {
  node: FlowTreeNode;
  eventEmitter: FlowEventTarget | undefined;
  processID: string;
  parent?: FlowTreeNode;
  activeEdges?: Partial<Edge>[];
}

const sleep = (time: number) =>
  new Promise((res) => setTimeout(res, time, "done sleeping"));

const flashNode = (
  node: Node,
  eventEmitter: FlowEventTarget,
  flashDuration: number = 200
) => {
  //for timeNode, if flashDuration < threshold -2. The set time is passed, so flash forever until new time set.
  const MS_IN_DAY = 86400000;
  flashDuration = flashDuration < -2 ? MS_IN_DAY : flashDuration;
  //TODO: check if timeNode, if so flash forever
  const timeOutId = setTimeout(
    () => eventEmitter?.onRemoveActiveNode(node),
    flashDuration
  );
  if (!useStore.getState().activeNode.has(node.id)) {
    eventEmitter?.onAddActiveNode(node);
  }
  return timeOutId;
};

// helper function for getting dynamic interval value
const getInterval = (node) => {
  // If LoopNode, return static interval. Dynamic update have NOT been implemented for LoopNode.
  if (node.type == NodeTypes.LoopNode) return null;
  const intervalMap = useStore.getState().intervalMap;
  const interval = intervalMap.get(node.id) * 1000 || 1000;
  return interval;
};
const intervalTimer = (callback, node: Node, eventEmitter: FlowEventTarget) => {
  let timeoutId;
  let interval = getInterval(node);
  const startTime = Date.now();
  let lastTime = Date.now();
  async function main() {
    interval = getInterval(node);
    console.log(`main func in intervalTimer, interval is ${interval}`);

    flashNode(node, eventEmitter);

    timeoutId = setTimeout(main, interval);

    callback();
  }
  main();
  return () => {
    clearTimeout(timeoutId);
  };
};

export const processNode = async ({
  node,
  eventEmitter,
  processID,
  activeEdges,
  parent,
}: ProcessNodeArgs) => {
  if (activeEdges) {
    eventEmitter?.onRemoveActiveEdge(activeEdges);
  }

  const curProcessID = useStore.getState().processID;

  if (processID !== curProcessID) {
    return;
  }

  eventEmitter?.onAddActiveNode(node);
  let i = 1;
  let interval;
  let children: Set<FlowTreeNode> = node.children;
  let alternateChildren: Set<FlowTreeNode> = new Set();
  let nextActiveEdges: Partial<Edge>[];
  let timer;

  if (parent) {
    nextActiveEdges = [{ source: parent.id, target: node.id }];
    eventEmitter?.onAddActiveEdge(nextActiveEdges);
  }

  try {
    switch (node.type) {
      case NodeTypes.AudioNode:
      case NodeTypes.DrumNode:
        const audio: Howl = new Howl({
          src: node.data.blobURL, // TODO: remove
          format: ["webm", "m4a", "mp3"],
          html5: true,
        });

        // http://alemangui.github.io/ramp-to-value
        // const audioCtx = Howler.ctx;
        // const gainNode = Howler.masterGain;

        const duration = audio.duration();
        audio.play();

        await new Promise((res, rej) => {
          timer = setInterval(() => {
            eventEmitter?.onAddActiveNode(node);
            eventEmitter?.onUpdateAudioNodeProgress({
              id: node.id,
              progress: audio.seek() / duration,
            });
          }, 250);

          const cleanupAudio = () => {
            res(null);
            clearInterval(timer);
            eventEmitter?.onRemoveActiveNode(node);
            eventEmitter?.onUpdateAudioNodeProgress({
              id: node.id,
              progress: 0,
            });
          };

          audio.once("end", (event) => {
            cleanupAudio();
          });

          audio.once("stop", (event) => {
            cleanupAudio();
          });
        });
        break;
      case NodeTypes.LoopNode:
      case NodeTypes.IntervalNode:
        interval = getInterval(node);
        // If i is not defined, it will be an 3 times loop by default
        // i = Number.isNaN(node.data.loop) ? Number.POSITIVE_INFINITY : node.data.loop
        i = Number.isNaN(node.data.loop) ? 3 : node.data.loop; //LZF to correct the loop number when its running the process
        console.log("i is ", i);
        const loopNodeChildrenInArray = [...node.children];
        console.log("loopNodeChildrenInArray", loopNodeChildrenInArray);
        children = new Set(
          loopNodeChildrenInArray.filter((child) =>
            child.sourceHandle.includes(`${node.id}-output`)
          )
        );
        alternateChildren = new Set(
          loopNodeChildrenInArray.filter((child) =>
            child.sourceHandle.includes(`${node.id}-exit`)
          )
        );

        console.log("alternateChildren", alternateChildren);
        break;
      case NodeTypes.RandomNode:
        // generate randomNum from 0-100.
        const randomNum = Math.round(Math.random() * 100);
        const probDistMap = useStore.getState().probDistMap;
        const probDist = probDistMap.get(node.id) ?? 50;
        const choice = randomNum >= probDist;
        //toDel
        console.log(
          `randomNum = ${randomNum}, probDist = ${probDist}, choice = ${Number(
            choice
          )}`
        );

        const randomNodeChildrenInArray = [...node.children];
        children = new Set(
          randomNodeChildrenInArray.filter((child) =>
            child.sourceHandle.includes(`${node.id}_${Number(choice)}`)
          )
        );
        break;
      case NodeTypes.SwitchNode:
        const switchNodeChildrenInArray = [...node.children];
        const switchMap = useStore.getState().switchMap;
        const storedSelectedSwitches = switchMap.get(node.id);
        // we want to utilize the store information to allow dynamic
        // updates of switches
        const activeHandles = new Set(
          (storedSelectedSwitches !== undefined
            ? [...storedSelectedSwitches]
            : node.data.selectedSwitches
          ).map((key) => `${node.id}_${key}`)
        );
        children = new Set(
          switchNodeChildrenInArray.filter(
            (child) =>
              intersection(activeHandles, new Set(child.sourceHandle)).size > 0
          )
        );
        break;
      case NodeTypes.TimeNode:
        break;
    }

    const next = async (nodes = children, shouldRemoveEdge = true) =>
      await Promise.all(
        Array.from(nodes).map(async (c) => {
          return processNode({
            node: c,
            eventEmitter: eventEmitter,
            processID,
            activeEdges: shouldRemoveEdge ? nextActiveEdges : null,
            parent: node,
          });
        })
      );

    console.log("interval", interval, i);

    // IntervalNode
    // only intervalNode has interval value, loopNode will have null
    if (interval) {
      await new Promise((res, rej) => {
        const cancelTimer = intervalTimer(
          async () => {
            const curProcessID = useStore.getState().processID;
            if (processID !== curProcessID) {
              console.log("Warning ProcessID !== curProcessID");
              cancelTimer();
              eventEmitter?.onUpdateLoopCount({ loop: 0, id: node.id });
              return res(null);
            }
            if (i < 1) {
              console.log("debug", i);
              cancelTimer();
              eventEmitter?.onUpdateLoopCount({ loop: 0, id: node.id });
              await next(alternateChildren);
              return res(null);
            } else {
              console.log("am i here i?");
              i--;
              eventEmitter?.onUpdateLoopCount({ loop: i, id: node.id });
              await next(children, false);
            }
          },
          node,
          eventEmitter
        );
      });
    } else {
      const noAudioNodes = [
        NodeTypes.PlayNode,
        NodeTypes.RandomNode,
        NodeTypes.SwitchNode,
        NodeTypes.TimeNode,
      ];

      // the three Nodes with no audio played should be branched to the while loop for flashNode effect
      if (i > 1 || noAudioNodes.includes(node.type as NodeTypes)) {
        while (i >= 1) {
          flashNode(node, eventEmitter);
          i--;
          eventEmitter?.onUpdateLoopCount({ loop: i, id: node.id });
          await next(children, false);
        }
        eventEmitter?.onUpdateLoopCount({ loop: 0, id: node.id });
      } else {
        // Regular Node
        await next(children);
        eventEmitter?.onUpdateLoopCount({ loop: 0, id: node.id });
      }
      await next(alternateChildren);
    }

    if (nextActiveEdges) {
      // this is for the last node in a process so that we can still deactivate edges
      eventEmitter?.onRemoveActiveEdge(nextActiveEdges);
    }
  } catch (err) {
    eventEmitter?.onRemoveActiveEdge(nextActiveEdges);
  }
};
