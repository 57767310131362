import React, { memo, useEffect, useMemo } from "react";
import { Handle, Position, NodeProps } from "react-flow-renderer";
import { Flex, Input, Text } from "@chakra-ui/react";
import { posthog } from "posthog-js";
import { stringify } from "flatted";
import styled from "@emotion/styled";

import withPlayBtn from "@/HOCs/withPlayBtn";
import useStore from "@/store/useStore";
import EnhancedHandle from "@/components/enhancedHandle";

const StyledInput = styled(Input)`
  padding: 8px;
  width: 48px;
  height: 32px;
  margin: 4px;
  display: inline-block;
  ::placeholder {
    color: Ivory;
  }
`;

export interface LoopNodeData {
  loop: number;
  showThenHandle?: boolean;
  onChange: (data: any) => void;
}

const LoopNode = memo(
  ({ data, isConnectable, id }: NodeProps<LoopNodeData>) => {
    const loopMap = useStore((state) => state.loopMap);
    // const [showThenHandle, setShowThenHandle] = React.useState(false);
    //Below is commented out by LZF
    const loop = useMemo(() => {
      const runningLoop = loopMap.get(id);
      if (runningLoop !== undefined) {
        return runningLoop;
      }
      return data.loop;
    }, [data.loop, loopMap]);

    useEffect(() => {
      if (!loop) {
        data.onChange({ value: 3, nodeID: id });
      }
    }, []);
    return (
      <>
        <EnhancedHandle
          type="target"
          position={Position.Left}
          isConnectable={isConnectable}
          id="loop-input"
        />
        <Flex
          h="100%"
          justify={"center"}
          align="center"
          direction={"row"}
          p={3}
        >
          <Text mb={1} fontSize={17}>
            Repeat your operations
            {/* <StyledInput
      <Flex h="100%" justify={"center"} align="center" direction={"row"} p={3}>
        <Text mb={1} fontSize={17}>
          Repeat your operations */}
            <StyledInput
              type="number"
              className="nodrag"
              value={isNaN(loop) ? "" : loop}
              onChange={(e) => {
                console.log(
                  "trigger onChange, e.target.value: ",
                  JSON.stringify(e.target.value)
                );
                // if (!e.target.value) {
                //   return;
                // }
                posthog.capture("onChangeLoop [LOOP]", {
                  payload: stringify({ nodeID: id, loop: e.target.value }),
                });
                data.onChange({ value: e.target.value, nodeID: id });
              }}
              onBlur={(e) => {
                console.log(
                  "trigger onBlur, e.target.value: ",
                  JSON.stringify(e.target.value)
                );
                if (!e.target.value) {
                  console.log("user input is empty");
                  posthog.capture("onChangeLoop [LOOP]", {
                    payload: stringify({ nodeID: id, loop: e.target.value }),
                  });
                  data.onChange({ value: 3, nodeID: id });
                }
              }}
            />
          </Text>
        </Flex>
        <EnhancedHandle
          annotation="do"
          type="source"
          position={Position.Right}
          isConnectable={isConnectable}
          onConnect={(_) => data.onChange({ showThenHandle: true, nodeID: id })}
          style={{ top: "20%" }}
          annotationStyle={{ right: -4 }}
          id={`${id}-output`}
        />
        <EnhancedHandle
          annotation="then"
          type="source"
          position={Position.Right}
          isConnectable={isConnectable}
          style={{
            top: "80%",
            visibility: data.showThenHandle ? "visible" : "hidden",
          }}
          id={`${id}-exit`}
        />
      </>
    );
  }
);

export default withPlayBtn(LoopNode);
