import React from 'react'
import styled from '@emotion/styled'
import { Box } from '@chakra-ui/react'

import playBtnSvg from '@/assets/play.svg';

const Container = styled(Box)`
    position: absolute;
    height: 48px;
    top: -62px;
    left: -6px;
    cursor: pointer;
    z-index: 99999; 
    
    img {
        height: 150%;
        width: auto;
    }
`
//# 99999 z-index
export interface NodePlayButtonProps {
    onClick: React.MouseEventHandler<HTMLDivElement>
}

export default ({ onClick }: NodePlayButtonProps) => {
    return (
        <Container onClick={onClick} className='nodrag node-button' draggable={false}>
            <img src={playBtnSvg} alt="play button" />
        </Container>
    )
}