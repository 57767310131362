import React, { useState, useEffect, useRef } from "react";
import {
  User,
  GoogleAuthProvider,
  FacebookAuthProvider,
  TwitterAuthProvider,
} from "firebase/auth";
import { Card, Spin, message } from "antd";
import { FirebaseAuth } from "react-firebaseui";
// use the below imports in dev, or login might not work
// import StyledFirebaseAuth from "react-firebaseui/StyledFirebaseAuth";
// const FirebaseAuth = StyledFirebaseAuth;

import styled from "@emotion/styled";

import { firebaseAuth } from "@/services/firebase";

const Container = styled.div`
  height: 100vh;
  width: 100vw;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  .ant-card {
    width: 640px;
    .ant-card-body {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }
  }
`;

export default (
  Child: React.FC<{
    user: User;
    setRequiredAuth: (required: boolean) => void;
    afterAuthCallback: React.MutableRefObject<Function>;
    signOut: () => void;
  }>
) => {
  return () => {
    const [user, setUser] = useState<User>();
    const [loading, setLoading] = useState(true);
    const [requiredAuth, setRequiredAuth] = useState(false);
    const afterAuthCallback = useRef<Function>();

    useEffect(() => {
      const subscriber = firebaseAuth.onAuthStateChanged((user) => {
        console.log("user", user);
        setUser(user);
        setLoading(false);
      });

      return () => {
        subscriber()
      }
    }, []);

    if (loading) {
      return (
        <Spin
          spinning
          style={{
            width: "100vw",
            height: "100vh",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        />
      );
    }

    const signOut = () => {
      firebaseAuth.signOut()
    };

    return user || !requiredAuth ? (
      <Child
        user={user}
        setRequiredAuth={setRequiredAuth}
        afterAuthCallback={afterAuthCallback}
        signOut={signOut}
      />
    ) : (
      <Container>
        <Card>
          <>
            <h2>Welcome to M-Flow! Please login first!</h2>
            <FirebaseAuth
              uiConfig={{
                signInFlow: "popup",
                signInOptions: [
                  GoogleAuthProvider.PROVIDER_ID,
                  FacebookAuthProvider.PROVIDER_ID,
                  TwitterAuthProvider.PROVIDER_ID,
                  "microsoft.com",
                  // EmailAuthProvider.PROVIDER_ID
                ],
                callbacks: {
                  signInFailure: (error) => {
                    console.log(error);
                  },
                },
              }}
              firebaseAuth={firebaseAuth}
            />
          </>
        </Card>
      </Container>
    );
  };
};
