import { Handle, HandleProps, Connection, OnConnectFunc } from "react-flow-renderer";
import React from "react";
import styled from "@emotion/styled";
import { CSSProperties } from "styled-components";

export interface EnhancedHandleProps extends HandleProps {
  annotation?: string;
  annotationStyle?: CSSProperties;
  style?: CSSProperties;
}

const Annotation = styled.span<{ right: number | string }>`
  position: absolute;
  right: ${({ right }) => `${right}px`};
  bottom: -32px;
  padding: 0 2px;
  border-radius: 2px;
  background-color: rgba(0, 0, 0, 0.3);
  pointer-events:none
`;

const EnhancedHandle: React.FC<EnhancedHandleProps> = ({
  annotation,
  annotationStyle,
  type,
  isValidConnection,
  ...props
}) => {

  const validateConnection = (connection: Connection) => {
    if (isValidConnection) {
      return isValidConnection(connection);
    }
    return true;
  };

  annotation = annotation ?? (type === "target" ? "IN" : "OUT");

  return (
    <Handle type={type} isValidConnection={validateConnection} {...props}>
      {annotation && (
        <Annotation
          right={type === "target" ? -2 : -10}
          style={annotationStyle}
        >
          {annotation}
        </Annotation>
      )}
    </Handle>
  );
};

export default EnhancedHandle;
